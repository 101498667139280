/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { i18n } from 'i18next'
import { LitElement } from 'lit'

type Constructor<T = {}> = new (...args: any[]) => T

export const localize =
  (i18next: i18n) =>
  <T extends Constructor<LitElement>>(superClass: T) => {
    class LocalizedElement extends superClass {
      private declare languageUpdated?: (i18next: i18n) => void
      private __i18next_callback__?: () => void

      connectedCallback() {
        var callback = () => {
          this.languageUpdated && this.languageUpdated(i18next)
          this.requestUpdate()
        }

        i18next.on('initialized', callback)
        i18next.on('languageChanged', callback)
        i18next.store.on('added', callback)

        this.__i18next_callback__ = callback

        super.connectedCallback()
      }

      disconnectedCallback() {
        super.connectedCallback()

        i18next.off('initialized', this.__i18next_callback__)
        i18next.off('languageChanged', this.__i18next_callback__)
        i18next.store.off('added', this.__i18next_callback__)
      }
    }

    return LocalizedElement as Constructor<LitElement> & T
  }
