import { css } from 'lit'

export const ButtonContainerStyles = css`
  .button-container {
    display: flex;
    margin-left: auto;
    padding: var(--spacing-small);
    gap: var(--spacing-small);
  }

  button {
    display: flex;
    align-items: center;
    background-color: var(--button-background-color, var(--md-sys-color-secondary-container));
    border: 1px solid var(--md-sys-color-secondary-container);
    border-radius: var(--button-border-radius);

    font-size: var(--fontsize-default);
    color: var(--button-color, var(--md-sys-color-on-secondary-container));
    text-wrap: nowrap;

    md-icon {
      margin: 4px 1px;

      --md-icon-size: var(--icon-size-small);
    }

    &:hover {
      background-color: var(--md-sys-color-secondary);
      color: var(--md-sys-color-on-secondary);

      border: 1px solid var(--md-sys-color-secondary);
    }
  }

  [filled] {
    font-variation-settings: 'FILL' 1;
  }

  [danger],
  button[danger],
  md-assist-chip[danger] {
    --md-assist-chip-outline-color: var(--md-sys-color-error);
    --md-assist-chip-elevated-container-color: var(--md-sys-color-error);

    --md-assist-chip-label-text-color: var(--md-sys-color-on-error);
    --md-assist-chip-leading-icon-color: var(--md-sys-color-on-error);

    --md-assist-chip-hover-label-text-color: var(--md-sys-color-error);
    --md-assist-chip-hover-leading-icon-color: var(--md-sys-color-error);

    color: var(--md-sys-color-on-error);
    background-color: var(--md-sys-color-error);
    border-color: var(--md-sys-color-error);

    &:hover {
      --md-assist-chip-outline-color: var(--md-sys-color-error);
      --md-assist-chip-elevated-container-color: var(--md-sys-color-on-error);

      --md-assist-chip-label-text-color: var(--md-sys-color-error);
      --md-assist-chip-leading-icon-color: var(--md-sys-color-error);

      color: var(--md-sys-color-error);
      background-color: var(--md-sys-color-on-error);
    }
  }

  select,
  input[type='text'] {
    background-color: var(--button-background-color, var(--md-sys-color-secondary-container));
    border: 1px solid var(--md-sys-color-outline);
    border-radius: var(--button-border-radius);
    padding: 0 var(--spacing-tiny);

    font-size: var(--fontsize-default);
    color: var(--button-color, var(--md-sys-color-on-secondary-container));
  }

  [filler] {
    flex: 1;
  }

  @media screen and (max-width: 460px) {
    .button-container {
      padding: 0;
      gap: 0;
    }

    button,
    md-icon {
      border-radius: 0;
    }

    button:not(:last-child),
    md-icon:not(:last-child) {
      border-right-width: 0;
    }
  }
`
