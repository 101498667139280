export enum UserStatus {
  INACTIVE = 'inactive',
  ACTIVATED = 'activated',
  DELETED = 'deleted',
  LOCKED = 'locked',
  BANNED = 'banned',
  PWD_RESET_REQUIRED = 'password_reset_required'
}

export class User {
  readonly id?: string

  name?: string
  description?: string

  email?: string

  creator?: User
  updater?: User

  createdAt?: Date
  updatedAt?: Date
}
