const addQueryString = (url: string, params: any) => {
  if (params && typeof params === 'object') {
    let queryString = ''
    // Must encode data
    for (const paramName in params) {
      queryString += '&' + encodeURIComponent(paramName) + '=' + encodeURIComponent(params[paramName])
    }
    if (!queryString) return url
    url = url + (url.indexOf('?') !== -1 ? '&' : '?') + queryString.slice(1)
  }

  return url
}

// fetch api stuff
const requestWithFetch = (options: any, url: any, payload: any, callback: (status: any, obj?: any) => void) => {
  if (options.queryStringParams) {
    url = addQueryString(url, options.queryStringParams)
  }
  const headers = typeof options.customHeaders === 'function' ? options.customHeaders() : options.customHeaders

  if (payload) headers['Content-Type'] = 'application/json'
  fetch(url, {
    ...(typeof options.requestOptions === 'function' ? options.requestOptions(payload) : options.requestOptions),
    method: payload ? 'POST' : 'GET',
    body: payload ? options.stringify(payload) : undefined,
    headers
  })
    .then(response => {
      if (!response.ok) return callback(response.statusText || 'Error', { status: response.status })
      response
        .text()
        .then(data => {
          callback(null, { status: response.status, data })
        })
        .catch(callback)
    })
    .catch(callback)
}

export const request = (
  options: any,
  url: any,
  payload: any,
  callback: (status: string, obj: { status: number }) => void
) => {
  if (typeof payload === 'function') {
    callback = payload
    payload = undefined
  }

  return requestWithFetch(options, url, payload, callback || (() => {}))
}
