/**
 * A regular expression pattern used to parse the pathname into different parts.
 * It captures the contextPath, prefix, domain, and path.
 * Example: /contextPathPrefix/domain/path/to/resource
 */
var CONTEXT_PATH_PREFIX = 'domain|business'

/**
 * Parses the given pathname and extracts information about the contextPath, prefix, domain, and path.
 *
 * @param {string} pathname - The pathname to parse.
 * @returns {object} - An object containing the parsed information:
 *   - contextPath: The context path portion of the pathname.
 *   - prefix: The prefix portion of the pathname.
 *   - domain: The domain portion of the pathname.
 *   - path: The remaining path after contextPath, prefix, and domain.
 */
export function getPathInfo(pathname: string) {
  var regexp = new RegExp(`(/(${CONTEXT_PATH_PREFIX})/([^/]+))?(/?.*)`)
  var matched = pathname.match(regexp)
  var contextPath = matched?.[1] || ''
  var prefix = matched?.[2] || ''
  var domain = matched?.[3] || ''
  var path = matched?.[4]

  return {
    contextPath,
    prefix,
    domain,
    path
  }
}

/**
 * Sets the context path prefix used for parsing pathnames.
 *
 * @param {string} contextPathPrefix - The new context path prefix to set.
 */
export function setContextPathPrefix(contextPathPrefix?: string) {
  console.log('context-path-prefix was just set to ', `'${contextPathPrefix || 'domain|business'}'`)
  CONTEXT_PATH_PREFIX = contextPathPrefix || 'domain|business'
}
