/*
 * [ Caution ]
 * Since this module is being used by a service worker, be very careful about adding imports or using functions for browsers.
 */

type OxObjectStore = {
  add: (value: any, key?: IDBValidKey | undefined) => IDBValidKey
  delete: (query: IDBValidKey | IDBKeyRange) => undefined
  clear: () => undefined
  get: (query: IDBValidKey | IDBKeyRange) => any
  getAll: (query?: IDBValidKey | IDBKeyRange | null | undefined, count?: number | undefined) => any[]
  getAllKeys: (query?: IDBValidKey | IDBKeyRange | null | undefined, count?: number | undefined) => IDBValidKey[]
  count: (query?: IDBValidKey | IDBKeyRange | undefined) => number
  put: (value: any, key?: IDBValidKey | undefined) => IDBValidKey
  openCursor: (
    query?: IDBValidKey | IDBKeyRange | null | undefined,
    direction?: IDBCursorDirection | undefined
  ) => IDBCursorWithValue | null
  openKeyCursor: (
    query?: IDBValidKey | IDBKeyRange | null | undefined,
    direction?: IDBCursorDirection | undefined
  ) => IDBCursor | null
  limit: (limit?: number) => undefined
}

/* promise queue */
class Queue {
  private static queue: {
    promise: Promise<IDBDatabase>
    resolve: (db: IDBDatabase) => void
    reject: (reason?: any) => void
  }[] = []

  private static workingOnPromise: boolean = false

  static enqueue(promise: Promise<any>) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        promise,
        resolve,
        reject
      })
      this.dequeue()
    })
  }

  static dequeue() {
    if (this.workingOnPromise) {
      return false
    }
    const item = this.queue.shift()
    if (!item) {
      return false
    }
    try {
      this.workingOnPromise = true
      item.promise
        .then(value => {
          this.workingOnPromise = false
          item.resolve(value)
          this.dequeue()
        })
        .catch(err => {
          this.workingOnPromise = false
          item.reject(err)
          this.dequeue()
        })
    } catch (err) {
      this.workingOnPromise = false
      item.reject(err)
      this.dequeue()
    }
    return true
  }
}

function getStore(storeName: string): OxObjectStore {
  return [
    'add',
    'delete',
    'clear',
    'get',
    'getAll',
    'getAllKeys',
    'count',
    'put',
    'openCursor',
    'openKeyCursor'
  ].reduce(
    (sum, m) => {
      sum[m] = async (...params: any) => {
        const db = (await getIndexDB()) as IDBDatabase

        const transaction = db.transaction(storeName, 'readwrite')
        const store = transaction.objectStore(storeName)
        const method: (...p: any) => any = (store as any)[m]
        const request = method.apply(store, params)

        return await new Promise((resolve, reject) => {
          request.onsuccess = (event: Event) => {
            resolve((event.target as IDBRequest)?.result)
          }

          request.onerror = (event: Event) => {
            reject(event)
          }
        })
      }

      return sum
    },
    {
      async limit(this: OxObjectStore, limit = 50) {
        const keys = (await this.getAllKeys()).slice(0, -limit)
        for (let i = 0; i < keys.length; i++) {
          await this.delete(keys[i])
        }
      }
    } as any
  )
}

function getIndexedDB(): IDBFactory {
  if (typeof window !== 'undefined') {
    return window.indexedDB
  } else {
    return self.indexedDB
  }
}

var db: IDBDatabase

function getIndexDB() {
  if (db) {
    return db
  }

  return Queue.enqueue(
    new Promise(function (resolve, reject) {
      const indexedDB = getIndexedDB()

      if (!indexedDB) {
        reject('this browser does not support indexedDB')
      }
      const request = indexedDB.open('things-factory-database')

      request.onerror = function (event) {
        console.log("Why didn't you allow my web app to use IndexedDB?!")
        reject(event)
      }

      request.onupgradeneeded = function (event: IDBVersionChangeEvent) {
        var db: IDBDatabase = (event.target as IDBRequest)?.result

        var store = db.createObjectStore('notifications', { keyPath: 'id', autoIncrement: true })
        store.createIndex('notification_id_unqiue', 'id', { unique: true })

        var store = db.createObjectStore('client_settings', { keyPath: 'key', autoIncrement: true })
        store.createIndex('client_setting_key_unqiue', 'key', { unique: true })
      }

      request.onsuccess = function (event) {
        console.log('IndexedDB opened successfully')
        db = request.result
        resolve(db)
      }
    })
  )
}

/* ready indexedDB Stores */
export const clientSettingStore: OxObjectStore = getStore('client_settings')
export const notificationStore: OxObjectStore = getStore('notifications')
