import '@operato/popup/ox-floating-overlay.js'
import '../components/ox-resize-splitter.js'

import { css, html, LitElement } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { customElement, property, state } from 'lit/decorators.js'
import { connect } from 'pwa-helpers/connect-mixin.js'

import { ScrollbarStyles } from '@operato/styles'

import { Viewpart, VIEWPART_LEVEL } from '../actions/layout.js'
import store from '../initializer.js'

@customElement('ox-nav-bar')
class NavBar extends connect(store)(LitElement) {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;

        position: relative;

        background-color: var(--nav-bar-background-color);
      }

      *[navbar] {
        display: flex;
        flex-direction: row;
      }
    `
  ]

  @property({ type: Boolean, attribute: 'fullbleed' }) fullbleed: boolean = false

  @state() viewparts: { [name: string]: Viewpart } = {}

  private _startWidth: number = 0

  render() {
    var viewparts = this.viewparts
    var navbars = Object.keys(viewparts)
      .map(name => {
        return {
          name,
          ...viewparts[name]
        }
      })
      .filter(viewpart => viewpart.position == 'navbar' && (!this.fullbleed || viewpart.hovering))

    navbars = [
      ...navbars.filter(viewpart => viewpart.level == VIEWPART_LEVEL.TOPMOST),
      ...navbars.filter(viewpart => viewpart.level !== VIEWPART_LEVEL.TOPMOST)
    ]

    return html`
      ${navbars.map(navbar =>
        !navbar.show
          ? html``
          : navbar.hovering
            ? html`
                <ox-floating-overlay
                  .backdrop=${navbar.backdrop}
                  direction="right"
                  .hovering=${navbar.hovering}
                  .name=${navbar.name}
                  .title=${navbar.title}
                  .help=${navbar.help}
                  .size=${navbar.size}
                  .closable=${navbar.closable}
                  .templateProperties=${navbar.templateProperties}
                  .historical=${true}
                  .search=${navbar.search}
                  .filter=${navbar.filter}
                  z-index=${ifDefined(navbar.zIndex)}
                  >${navbar.template}</ox-floating-overlay
                >
              `
            : html`
                <div navbar>${navbar.template}</div>
                ${navbar.resizable
                  ? html`
                      <ox-resize-splitter
                        @splitter-dragstart=${(e: CustomEvent) => this.resizeStart(e)}
                        @splitter-drag=${(e: CustomEvent) => this.resizeDrag(e)}
                        vertical
                      ></ox-resize-splitter>
                    `
                  : html``}
              `
      )}
    `
  }

  resizeStart(e: CustomEvent) {
    this._startWidth = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement).offsetWidth
  }

  resizeDrag(e: CustomEvent) {
    var delta = e.detail

    var x = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement).children
    Array.from(x).forEach(ele => {
      ;(ele as HTMLElement).style.width = `${this._startWidth + delta.x}px`
    })
  }

  stateChanged(state: any) {
    this.viewparts = state.layout.viewparts || {}
  }
}
