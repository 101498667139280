import { LitElement, css, html } from 'lit'

import { customElement } from 'lit/decorators.js'

@customElement('ox-resize-splitter')
export class ResizeSplitter extends LitElement {
  static styles = css`
    :host {
      position: relative;
      opacity: 0.7;
      background-color: var(--splitter-background-color);
    }

    :host(:hover) {
      background-color: var(--splitter-hover-background-color);
    }

    div {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  `

  private dragstart?: { x: number; y: number }

  connectedCallback() {
    super.connectedCallback()

    if (this.hasAttribute('vertical')) {
      this.style.width = '3px'
      this.style.height = '100%'
      this.style.cursor = 'col-resize'
    } else {
      this.style.width = '100%'
      this.style.height = '3px'
      this.style.cursor = 'row-resize'
    }
  }

  render() {
    return html` <div @mousedown=${(e: MouseEvent) => this.onMouseDown(e)}></div> `
  }

  // TODO onDrag 이벤트가 계속 발생하므로 처리하는 성능 저하됨. 그래서 throttling 하도록 함
  _throttled(delay: number, fn: (...args: any) => void) {
    let lastCall = 0
    return function (...args: any) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  }

  private onMouseDown(e: MouseEvent) {
    this.dragstart = {
      x: e.clientX,
      y: e.clientY
    }

    this.dispatchEvent(
      new CustomEvent('splitter-dragstart', {
        bubbles: true,
        composed: true
      })
    )

    window.addEventListener('mousemove', this.onMouseMoveHandler)
    window.addEventListener('mouseup', this.onMouseUpHandler)

    e.stopPropagation()
  }

  onMouseMoveHandler = this._throttled(100, this.onMouseMove.bind(this)) // this.onMouseMove.bind(this)
  onMouseUpHandler = this.onMouseUp.bind(this)

  private onMouseMove(e: MouseEvent) {
    if (!this.dragstart) {
      return
    }

    e.preventDefault()

    this.dispatchEvent(
      new CustomEvent('splitter-drag', {
        bubbles: true,
        composed: true,
        detail: {
          x: e.clientX - this.dragstart!.x,
          y: e.clientY - this.dragstart!.y
        }
      })
    )
    requestAnimationFrame(() => {
      dispatchEvent(new Event('resize'))
    })

    if (this.hasAttribute('vertical')) {
      document.body.style.cursor = 'col-resize'
    } else {
      document.body.style.cursor = 'row-resize'
    }

    document.body.style.userSelect = 'none'

    e.stopPropagation()
  }

  private onMouseUp(e: MouseEvent) {
    window.removeEventListener('mousemove', this.onMouseMoveHandler)
    window.removeEventListener('mouseup', this.onMouseUpHandler)

    this.dispatchEvent(
      new CustomEvent('splitter-dragend', {
        bubbles: true,
        composed: true,
        detail: {
          x: e.clientX - this.dragstart!.x,
          y: e.clientY - this.dragstart!.y
        }
      })
    )

    requestAnimationFrame(() => {
      dispatchEvent(new Event('resize'))
      delete this.dragstart
    })

    document.body.style.cursor = ''
    document.body.style.userSelect = ''

    e.stopPropagation()
  }
}
