import '@material/web/button/filled-button.js'
import '@material/web/icon/icon.js'

import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { connect } from 'pwa-helpers/connect-mixin.js'

import { CLOSE_SNACKBAR } from '../actions/snackbar'
import store from '../initializer.js'

@customElement('ox-snack-bar')
class SnackBar extends connect(store)(LitElement) {
  static styles = css`
    :host {
      display: block;
      position: fixed;
      top: 100%;
      left: 0;
      right: 0;
      padding: 12px;
      color: var(--md-sys-color-inverse-on-surface, white);
      background-color: var(--md-sys-color-inverse-surface, black);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      text-align: center;
      will-change: transform;
      transform: translate3d(0, 0, 0);
      transition-property: visibility, transform;
      transition-duration: 0.2s;
      visibility: hidden;
    }

    md-filled-button {
      margin-top: var(--spacing-medium);
    }

    :host([active]) {
      visibility: visible;
      transform: translate3d(0, -100%, 0);
    }

    md-icon {
      vertical-align: middle;
      max-width: 20px;
      --md-icon-size: 1.5em;
    }

    .info {
      color: var(--status-info-color);
    }

    .warn {
      color: var(--status-warning-color);
    }

    .error {
      color: var(--status-danger-color);
    }

    @media (min-width: 460px) {
      :host {
        width: 320px;
        margin: auto;
      }
    }
  `

  @property({ type: String }) level: 'info' | 'warn' | 'error' = 'info'
  @property({ type: String }) message: string = ''
  @property({ type: Boolean, reflect: true }) active: boolean = false
  @property({ type: Object }) action: any

  render() {
    return html`
      <span>
        <md-icon class=${this.level}
          >${this.level == 'info'
            ? html` notification_important `
            : this.level == 'warn'
              ? html` warning `
              : this.level == 'error'
                ? html` error `
                : html``}</md-icon
        >
      </span>
      <span>${this.message}</span>
      ${this.action && this.action.label
        ? html`
            <md-filled-button
              @click=${() => {
                store.dispatch({ type: CLOSE_SNACKBAR })
                this.action.callback()
              }}
              >${this.action.label}</md-filled-button
            >
          `
        : html``}
    `
  }

  stateChanged(state: any) {
    var { level, message, snackbarOpened, action } = state.snackbar || {}

    this.level = level
    this.message = message
    this.active = snackbarOpened
    this.action = action
  }
}
