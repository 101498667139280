/**
 * Detects if the content of an HTMLElement overflows its boundaries.
 *
 * @param {HTMLElement} el - The HTMLElement to check for overflow.
 * @returns {boolean} - `true` if overflow is detected, `false` otherwise.
 */
export function hasOverflow(el: HTMLElement): boolean {
  const computedStyle = getComputedStyle(el)
  const originalOverflow = computedStyle.overflow

  if (originalOverflow === 'visible') {
    el.style.overflow = 'hidden'
  }

  const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight

  if (originalOverflow === 'visible') {
    el.style.overflow = ''
  }

  return isOverflowing
}
