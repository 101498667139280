import { UPDATE_BUSY } from '../actions/busy.js'

const INITIAL_STATE: {
  busy: boolean
} = {
  busy: false
}

const busy = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_BUSY:
      return {
        busy: action.busy
      }

    default:
      return state
  }
}

export default busy
