import { css } from 'lit'

export const HeadroomStyles = css`
  .headroom {
    transition: transform 200ms linear;
  }

  .headroom--pinned {
    transform: translateY(0%);
  }

  .headroom--unpinned {
    transform: translateY(-100%);
  }
`
