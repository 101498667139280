import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

@customElement('ox-i18n-selector')
export class I18nSelector extends LitElement {
  static styles = css`
    * {
      box-sizing: border-box;
    }
    *:focus {
      outline: none;
    }
    select {
      border: var(--i18n-selector-field-border, var(--input-field-border));
      border-radius: var(--i18n-selector-field-border-radius, 0);
      background-color: var(--i18n-selector-field-background-color, var(--md-sys-color-surface));
      margin: var(--i18n-selector-field-margin, 0);
      padding: var(--i18n-selector-field-padding, 0);
      font: var(--i18n-selector-field-font, var(--input-field-font));
      font-size: var(--i18n-selector-field-font-size, 15px);
      width: var(--i18n-selector-field-width, 100%);
      color: var(--i18n-selector-field-color, var(--md-sys-color-on-surface));
    }
    select:focus {
      border: 1px solid var(--focus-background-color);
    }
    option {
      background-color: var(--md-sys-color-on-primary-container, #585858);
      color: var(--md-sys-color-surface, #fff);
    }

    ::placeholder {
      font-size: 0.8rem;
      text-transform: capitalize;
    }
  `

  @property({ type: String, attribute: true }) value: string = ''
  @property({ type: Array }) languages: { code: string; display: string }[] = []

  render() {
    const value = this.value

    return html`
      <select .value=${this.value} @change=${(e: Event) => this.onLocaleChanged((e.target as HTMLSelectElement).value)}>
        <option value="" ?selected=${!value}></option>
        ${(this.languages || []).map(
          ({ code, display }) => html`
            <option value=${code} ?selected=${value?.startsWith(code.substring(0, 2))}>${display}</option>
          `
        )}
      </select>
    `
  }

  async onLocaleChanged(value: string) {
    if (!value) {
      return
    }

    this.value = value

    this.dispatchEvent(
      new CustomEvent('change', {
        composed: true,
        bubbles: true,
        detail: value
      })
    )
  }
}
