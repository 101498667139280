export const UPDATE_MODULES = 'UPDATE_MODULES'
export const UPDATE_BASE_URL = 'UPDATE_BASE_URL'
export const UPDATE_CONTEXT_PATH = 'UPDATE_CONTEXT_PATH'
export const SET_DOMAINS = 'SET-DOMAINS'

export const updateDomains =
  (
    domains: {
      name: string
      subdomain: string
    }[],
    domain: {
      name: string
      subdomain: string
    }
  ) =>
  (dispatch: any) => {
    dispatch({
      type: SET_DOMAINS,
      domains,
      domain
    })
  }
