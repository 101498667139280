import { getPathInfo } from '@operato/utils'

import { SET_DOMAINS, UPDATE_BASE_URL, UPDATE_CONTEXT_PATH, UPDATE_MODULES } from '../actions/app.js'

const INITIAL_STATE: {
  baseUrl: string
  contextPath: string
  domains: {
    name: string
    subdomain: string
  }[]
} = {
  baseUrl: location.origin,
  contextPath: getPathInfo(location.pathname).contextPath,
  domains: []
}

const app = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_MODULES:
      return {
        ...state,
        modules: action.modules
      }
    case UPDATE_BASE_URL:
      return {
        ...state,
        baseUrl: action.baseUrl
      }
    case UPDATE_CONTEXT_PATH:
      return {
        ...state,
        contextPath: action.contextPath
      }

    case SET_DOMAINS:
      return {
        ...state,
        domains: action.domains,
        domain: action.domain
      }

    default:
      return state
  }
}

export default app
