import { LitElement, PropertyValues } from 'lit'
import { property } from 'lit/decorators.js'
import isEqual from 'lodash-es/isEqual'

import { UPDATE_CONTEXT } from '../../actions/const'
import { store } from '../../store'

export type PageLifecycle = {
  active: boolean
  params: object
  resourceId?: string
  page?: string
}

function diff(after: any, before: any): any {
  var changed = false
  var changes: { [key: string]: any } = {}

  Object.getOwnPropertyNames(after).forEach(function (key) {
    let before_val = before[key]
    let after_val = after[key]

    if (!isEqual(before_val, after_val)) {
      changes[key] = after_val
      changed = true
    }
  })

  return changed && changes
}

/**
 * PageView is a base class for creating page elements with lifecycle management.
 * Subclasses can extend PageView to define custom behavior and handle page lifecycle events.
 */
export class PageView extends LitElement {
  /**
   * Determines whether the page can be deactivated. Subclasses can override this method
   * to implement custom deactivation logic.
   * @returns A Promise that resolves to true if the page can be deactivated, or false otherwise.
   */
  async canDeactivate(): Promise<boolean> {
    return true
  }

  /**
   * Determines whether the page should update. This method is called whenever there are
   * changes to the page's properties.
   * @param changes - A map of changed property values.
   * @returns True if the page should update, or false otherwise.
   */
  shouldUpdate(changes: PropertyValues<this>) {
    var active = String(this.active) == 'true'
    var { active: oldActive = false } = this._oldLifecycleInfo$ || {}

    /*
     * page lifecycle
     * case 1. page가 새로 activate 되었다.
     * case 2. page가 active 상태에서 lifecycle 정보가 바뀌었다.
     **/
    if (active) {
      this.pageUpdate({
        active
      })
    } else if (oldActive) {
      this.pageUpdate({
        active
      })
    }

    return active
  }

  /**
   * Indicates whether the page is currently active.
   */
  @property({ type: Boolean }) active: boolean = false

  /**
   * Stores information about the page's lifecycle.
   */
  @property({ type: Object }) lifecycle!: PageLifecycle

  /**
   * The context path for the page.
   */
  @property({ type: String, attribute: 'context-path' }) contextPath?: string

  _oldLifecycleInfo$: any

  /* lifecycle */

  /**
   * Handles page updates and lifecycle events. Subclasses can override this method
   * to implement custom logic for initializing, updating, and disposing of the page.
   * @param changes - A map of changed properties.
   * @param force - If true, forces an update of the page.
   */
  async pageUpdate(changes: any = {}, force: boolean = false) {
    var before = this._oldLifecycleInfo$ || {}

    var after = {
      ...before,
      ...this.lifecycle,
      contextPath: this.contextPath,
      ...changes
    }

    if (!('initialized' in changes) && after.active && !before.initialized) {
      after.initialized = true
    }

    if (force) {
      after.updated = Date.now()
    }

    var changed = diff(after, before)
    if (!changed) {
      return
    }

    this._oldLifecycleInfo$ = after

    /* page의 이미 초기화된 상태에서 contextPath가 바뀐다면, 무조건 page가 리셋되어야 한다. */
    if (before.initialized && changed.contextPath) {
      await this.pageReset()
      return
    }

    if (changed.initialized) {
      await this.pageInitialized(after)
    }

    if ('initialized' in changed) {
      if (changed.initialized) {
        /*
         * 방금 초기화된 경우라면, 엘리먼트들이 만들어지지 않았을 가능성이 있으므로,
         * 다음 animationFrame에서 pageUpdated 콜백을 호출한다.
         */
        requestAnimationFrame(async () => {
          await this.pageUpdated(changed, after, before)
          /* active page인 경우에는, page Context 갱신도 필요할 것이다. */
          after.active && this.updateContext()
        })
      } else {
        await this.pageDisposed(after)
      }
    } else {
      await this.pageUpdated(changed, after, before)
      /* active page인 경우에는, page Context 갱신도 필요할 것이다. */
      after.active && this.updateContext()
    }
  }

  /**
   * Resets the page. Subclasses can override this method to perform custom reset logic.
   */
  async pageReset() {
    var { initialized } = this._oldLifecycleInfo$ || {}

    if (initialized) {
      await this.pageDispose()
      await this.pageUpdate({}, true)
    }
  }

  /**
   * Disposes of the page. Subclasses can override this method to perform custom disposal logic.
   */
  async pageDispose() {
    await this.pageUpdate({
      initialized: false
    })
  }

  /**
   * Initializes the page. Subclasses can override this method to perform custom initialization logic.
   * @param pageInfo - Information about the page's state.
   */
  pageInitialized(pageInfo: any) {}

  /**
   * Handles page updates and changes in properties.
   * Subclasses can override this method to implement custom update logic.
   * @param changes - A map of changed properties.
   * @param after - The current state of the page.
   * @param before - The previous state of the page.
   */
  pageUpdated(changes: any, after: any, before: any) {}

  /**
   * Handles the disposal of the page. Subclasses can override this method
   * to implement custom disposal logic.
   * @param pageInfo - Information about the page's state.
   */
  pageDisposed(pageInfo: any) {}

  /* context */
  updateContext(override?: any) {
    store.dispatch({
      type: UPDATE_CONTEXT,
      context: override ? { ...this.context, ...override } : this.context
    })
  }

  /**
   * Updates the context of the page. Subclasses can override the `context` getter
   * to provide specific context information for the page. The context will be updated
   * using the `updateContext` method inherited from PageView.
   * @param override - An optional object with context properties to override.
   */
  get context() {
    return {}
  }
}
