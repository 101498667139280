import { setActiveRequestCounterCallback  } from '@operato/graphql'
import { store } from '../store'

export const UPDATE_BUSY = 'UPDATE_BUSY'

setActiveRequestCounterCallback((count: number) => {
  store.dispatch(updateBusy(count != 0) as any)
})

export const updateBusy = (busy: boolean) => (dispatch: any) => {
  dispatch({
    type: UPDATE_BUSY,
    busy
  })
}
