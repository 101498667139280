/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { LitElement } from 'lit'
import { property } from 'lit/decorators.js'

export abstract class OxFormField extends LitElement {
  @property({ type: String, attribute: true }) name?: string
  @property({ type: Boolean }) disabled?: boolean
  @property() value?: any

  protected _form: HTMLFormElement | null = null
  protected _formdataEventHandler: EventListener | null = null

  override connectedCallback(): void {
    super.connectedCallback()

    if (this.name) {
      this._form = this.closest('form')
      if (this._form) {
        this._formdataEventHandler = this.appendFormData.bind(this) as EventListener
        this._form.addEventListener('formdata', this._formdataEventHandler)
      }
    }
  }

  override disconnectedCallback(): void {
    super.disconnectedCallback()

    if (this._form) {
      this._form.removeEventListener('formdata', this._formdataEventHandler!)
      this._form = null
      this._formdataEventHandler = null
    }
  }

  protected appendFormData({ formData }: FormDataEvent): void {
    if (!this.name) return

    var values = this.value
    if (!(values instanceof Array)) {
      values = [this.value]
    }

    values.forEach((value: any) => {
      formData.append(
        this.name!,
        typeof value === 'string' ? value : value === undefined || value === null ? '' : JSON.stringify(value)
      )
    })
  }
}
