import { css } from 'lit'

export const CommonHeaderStyles = css`
  .header {
    display: grid;
    grid-template-areas:
      'title actions'
      'filters filters';
    gap: var(--spacing-small) var(--spacing-medium);
    padding: calc(var(--spacing-medium) / 2) var(--spacing-medium);

    align-items: center;

    .title {
      grid-area: title;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-small);

      font: var(--subtitle-font);
      color: var(--md-sys-color-on-secondary-container);
      text-transform: capitalize;
    }

    .filters {
      grid-area: filters;
      border: none;
      border-radius: var(--md-sys-shape-corner-small, 5px);
      background-color: var(--md-sys-color-surface);
      padding: var(--spacing-medium) var(--spacing-large);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-large);

      .filter {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: var(--spacing-small);
      }

      label {
        font: var(--md-sys-typescale-label-medium-weight, var(--md-ref-typeface-weight-medium, 500))
          var(--fontsize-default) var(--theme-font);
        color: var(--md-sys-color-primary);
      }
    }

    .filters:empty {
      display: none;
    }

    .actions {
      grid-area: actions;
      margin-left: auto;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: var(--spacing-small);

      * {
        cursor: pointer;
      }

      .textbtn,
      .iconbtn {
        background-color: var(--md-sys-color-secondary-container);
        border: var(--button-border);
        border-radius: var(--button-border-radius);

        color: var(--md-sys-color-on-secondary-container);
        font: var(--button-font);

        &:hover {
          border: var(--button-activ-border);
          background-color: var(--button-activ-background-color);
          box-shadow: var(--button-active-box-shadow);

          color: var(--md-sys-color-on-secondary);
        }
      }

      .iconbtn {
        line-height: 0.9;

        md-icon {
          --md-icon-size: var(--icon-size-medium);
        }
      }

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--spacing-small);
        padding: var(--spacing-tiny) var(--spacing-small);
      }
    }

    :empty {
      padding: 0;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    text-align: right;
    background-color: var(--md-sys-color-surface-variant);
    padding: 0;

    button {
      display: flex;
      gap: var(--spacing-medium);
      align-items: center;
      justify-content: center;
      padding: var(--spacing-tiny) var(--spacing-medium);
      background-color: transparent;
      color: var(--md-sys-color-on-surface);
      font-size: var(--md-sys-typescale-title-medium-size, 1rem);
      line-height: 2;
      border-width: 0;
      border-radius: 0;
      border-left: 1px solid var(--md-sys-color-surface);
    }

    button[ok],
    button[done] {
      color: var(--md-sys-color-on-primary);
      background-color: var(--md-sys-color-primary);
    }

    [filler] {
      margin-left: auto;
    }
  }

  [danger],
  button[danger],
  md-assist-chip[danger] {
    --md-assist-chip-outline-color: var(--md-sys-color-error);
    --md-assist-chip-elevated-container-color: var(--md-sys-color-error);

    --md-assist-chip-label-text-color: var(--md-sys-color-on-error);
    --md-assist-chip-leading-icon-color: var(--md-sys-color-on-error);

    --md-assist-chip-hover-label-text-color: var(--md-sys-color-error);
    --md-assist-chip-hover-leading-icon-color: var(--md-sys-color-error);

    color: var(--md-sys-color-on-error);
    background-color: var(--md-sys-color-error);
    border-color: var(--md-sys-color-error);

    &:hover {
      --md-assist-chip-outline-color: var(--md-sys-color-error);
      --md-assist-chip-elevated-container-color: var(--md-sys-color-on-error);

      --md-assist-chip-label-text-color: var(--md-sys-color-error);
      --md-assist-chip-leading-icon-color: var(--md-sys-color-error);

      color: var(--md-sys-color-error);
      background-color: var(--md-sys-color-on-error);
    }
  }

  @media only screen and (max-width: 460px) {
    .header {
      padding: 0;
      gap: 0;

      grid-template-areas:
        'actions'
        'filters';

      .title {
        display: none;
      }

      .filters {
        flex-wrap: wrap;
        gap: var(--spacing-medium);
        border: none;
      }

      .actions {
        background-color: var(--md-sys-color-on-secondary-container);
        margin: 0;
      }
    }
  }
`
