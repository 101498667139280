import '@operato/popup/ox-floating-overlay.js'
import '../components/ox-resize-splitter.js'

import { css, html, LitElement } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { customElement, property, state } from 'lit/decorators.js'
import { connect } from 'pwa-helpers/connect-mixin.js'

import { Viewpart, VIEWPART_LEVEL } from '../actions/layout.js'
import store from '../initializer.js'

@customElement('ox-header-bar')
class HeaderBar extends connect(store)(LitElement) {
  static styles = [
    css`
      :host {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;

        position: relative;

        background-color: var(--header-bar-background-color);
      }

      *[headerbar] {
        display: block;
      }
    `
  ]

  @property({ type: Boolean, attribute: 'fullbleed' }) fullbleed: boolean = false

  @state() viewparts: { [name: string]: Viewpart } = {}

  private _startHeight: number = 0

  render() {
    var viewparts = this.viewparts
    var headerbars = Object.keys(viewparts)
      .map(name => {
        return {
          name,
          ...viewparts[name]
        }
      })
      .filter(viewpart => viewpart.position == 'headerbar' && (!this.fullbleed || viewpart.hovering))

    headerbars = [
      ...headerbars.filter(viewpart => viewpart.level == VIEWPART_LEVEL.TOPMOST),
      ...headerbars.filter(viewpart => viewpart.level !== VIEWPART_LEVEL.TOPMOST)
    ]

    return html`
      ${headerbars.map(headerbar =>
        !headerbar.show
          ? html``
          : headerbar.hovering
            ? html`
                <ox-floating-overlay
                  .backdrop=${headerbar.backdrop}
                  direction="down"
                  .hovering=${headerbar.hovering}
                  .name=${headerbar.name}
                  .title=${headerbar.title}
                  .help=${headerbar.help}
                  .size=${headerbar.size}
                  .closable=${headerbar.closable}
                  .templateProperties=${headerbar.templateProperties}
                  .historical=${true}
                  .search=${headerbar.search}
                  .filter=${headerbar.filter}
                  z-index=${ifDefined(headerbar.zIndex)}
                  >${headerbar.template}</ox-floating-overlay
                >
              `
            : html`
                <div headerbar>${headerbar.template}</div>
                ${headerbar.resizable
                  ? html`
                      <ox-resize-splitter
                        @splitter-dragstart=${(e: CustomEvent) => this.resizeStart(e)}
                        @splitter-drag=${(e: CustomEvent) => this.resizeDrag(e)}
                      ></ox-resize-splitter>
                    `
                  : html``}
              `
      )}
    `
  }

  resizeStart(e: CustomEvent) {
    this._startHeight = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement).offsetHeight
  }

  resizeDrag(e: CustomEvent) {
    var delta = e.detail

    var x = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement).children
    Array.from(x).forEach(ele => {
      ;(ele as HTMLElement).style.height = `${this._startHeight + delta.y}px`
    })
  }

  stateChanged(state: any) {
    this.viewparts = state.layout.viewparts || {}
  }
}
