import { css, html, LitElement, nothing } from 'lit'
import { render } from 'lit-html'
import { customElement, property, state } from 'lit/decorators.js'

import { ScrollbarStyles } from '@operato/styles'

import { convertToFixedPosition } from './position-converter.js'

declare global {
  interface Window {
    POPUP_DEBUG?: boolean
  }
}

/**
 * Custom element class representing the 'ox-popup' component.
 *
 * This component provides the functionality to display a popup with various configuration options.
 * It can be used to show additional information, notifications, or user interactions within a web application.
 *
 * @fires ox-close - Dispatched when the popup is closed.
 * @fires ox-collapse - Dispatched when the popup is collapsed.
 */
@customElement('ox-popup')
export class OxPopup extends LitElement {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        position: absolute;
        display: none;
        z-index: 1000; /* Increased z-index to ensure it's on top */
        padding: 0;
        box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        min-width: fit-content;
        line-height: initial;
        text-align: initial;
        background-color: var(--ox-popup-list-background-color, var(--md-sys-color-surface));
        color: var(--ox-popup-list-color, var(--md-sys-color-on-surface));
        margin: 0;
      }

      :host([active]) {
        display: flex;
        flex-direction: column;
      }

      :host(*:focus) {
        outline: none;
      }

      #backdrop {
        position: fixed;
        top: -100vh;
        left: -100vw;
        width: 300vw;
        height: 300vh;
        background-color: black;
        opacity: 0.5;
      }
    `
  ]

  @property({ type: Boolean, attribute: 'prevent-close-on-blur' }) preventCloseOnBlur: boolean = false
  @property({ type: Boolean, attribute: 'backdrop' }) backdrop: boolean = false

  private lastActive: HTMLElement = document.activeElement as HTMLElement
  protected removeAfterUse: boolean = false

  render() {
    return html`
      ${this.backdrop ? html`<div id="backdrop" @click=${() => this.close()}></div>` : nothing}
      <slot></slot>
    `
  }

  protected _onfocusout: (e: FocusEvent) => void = function (this: OxPopup, e: FocusEvent) {
    const to = e.relatedTarget as HTMLElement

    if (!this.contains(to)) {
      !this.preventCloseOnBlur && !window.POPUP_DEBUG && this.close()
    }
  }.bind(this)

  protected _onkeydown: (e: KeyboardEvent) => void = function (this: OxPopup, e: KeyboardEvent) {
    e.stopPropagation()

    switch (e.key) {
      case 'Esc': // for IE/Edge
      case 'Escape':
        this.close()
        break
    }
  }.bind(this)

  protected _onkeyup: (e: KeyboardEvent) => void = function (this: OxPopup, e: KeyboardEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _onmouseup: (e: MouseEvent) => void = function (this: OxPopup, e: MouseEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _onmousedown: (e: MouseEvent) => void = function (this: OxPopup, e: MouseEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _oncontextmenu: (e: Event) => void = function (this: OxPopup, e: Event) {
    e.stopPropagation()
    // this.close()
  }.bind(this)

  protected _onclick: (e: MouseEvent) => void = function (this: OxPopup, e: MouseEvent) {
    e.stopPropagation()
  }.bind(this)

  protected _onclose: (e: Event) => void = function (this: OxPopup, e: Event) {
    this.close()
  }.bind(this)

  protected _oncollapse: (e: Event) => void = function (this: OxPopup, e: Event) {
    e.stopPropagation()
    this.close()
  }.bind(this)

  protected _onwindowblur: (e: Event) => void = function (this: OxPopup, e: Event) {
    !this.preventCloseOnBlur && !window.POPUP_DEBUG && this.close()
  }.bind(this)

  connectedCallback() {
    super.connectedCallback()

    this.addEventListener('focusout', this._onfocusout)
    this.addEventListener('keydown', this._onkeydown)
    this.addEventListener('keyup', this._onkeyup)
    this.addEventListener('click', this._onclick)
    this.addEventListener('mouseup', this._onmouseup)
    this.addEventListener('mousedown', this._onmousedown)
    this.addEventListener('contextmenu', this._oncontextmenu)
    this.addEventListener('ox-close', this._onclose)
    this.addEventListener('ox-collapse', this._oncollapse)

    this.setAttribute('tabindex', '0') // make this element focusable
    this.guaranteeFocus()
  }

  /**
   * Configuration for opening ox-popup
   *
   * @typedef {Object} PopupOpenOptions
   * @property {HTMLTemplate} template HTMLTemplate to be displayed inside the popup
   * @property {Number} top The position-top where the pop-up will be displayed
   * @property {Number} left The position-left where the pop-up will be displayed
   * @property {Number} right The position-right where the pop-up will be displayed
   * @property {Number} bottom The position-bottom where the pop-up will be displayed
   * @property {string} width The maximum width of the popup (CSS string)
   * @property {string} height The maximum height of the popup (CSS string)
   * @property {HTMLElement} parent Popup's parent element
   * @property {string} style Additional CSS styles for the popup
   * @property {boolean} preventCloseOnBlur Flag to prevent closing the popup on blur
   * @property {boolean} backdrop If true, a semi-transparent background will be applied behind the popup, dimming the rest of the page.
   */
  static open({
    template,
    top,
    left,
    right,
    bottom,
    width,
    height,
    parent,
    style,
    preventCloseOnBlur,
    backdrop
  }: {
    template: unknown
    top?: number
    left?: number
    right?: number
    bottom?: number
    width?: string
    height?: string
    parent?: Element | null
    style?: string
    preventCloseOnBlur?: boolean
    backdrop?: boolean
  }): OxPopup {
    const target = document.createElement('ox-popup') as OxPopup
    if (preventCloseOnBlur) {
      target.preventCloseOnBlur = preventCloseOnBlur
    }

    if (backdrop) {
      target.backdrop = backdrop
    }

    if (style) {
      target.style.cssText = style
    }

    render(template, target)

    if (parent) {
      var { left, top, right, bottom } = convertToFixedPosition({
        left,
        top,
        right,
        bottom,
        relativeElement: parent as HTMLElement
      })
    }

    document.body.appendChild(target)
    target.removeAfterUse = true
    target.open({ top, left, right, bottom, width, height })

    return target
  }

  /**
   * Opens the 'ox-popup' with the specified position and dimensions.
   *
   * @param {Object} options - An object specifying the position and dimensions of the popup.
   * @param {number} options.left - The left position (in pixels) where the popup should be displayed. If not provided, it will be centered horizontally.
   * @param {number} options.top - The top position (in pixels) where the popup should be displayed. If not provided, it will be centered vertically.
   * @param {number} options.right - The right position (in pixels) where the popup should be displayed. If provided, it will override the 'left' value.
   * @param {number} options.bottom - The bottom position (in pixels) where the popup should be displayed. If provided, it will override the 'top' value.
   * @param {string} options.width - The maximum width of the popup (CSS string). For example, '300px'.
   * @param {string} options.height - The maximum height of the popup (CSS string). For example, '200px'.
   * @param {boolean} [options.silent=false] - A flag indicating whether the popup should auto-focus or not. If set to true, the popup won't attempt to focus on any element.
   * @param {boolean} [options.fixed=false] - A flag indicating whether the popup should be positioned fixed relative to the viewport. If set to true, the popup will be fixed.
   */
  open({
    left,
    top,
    right,
    bottom,
    width,
    height,
    silent = false,
    fixed = false
  }: {
    left?: number
    top?: number
    right?: number
    bottom?: number
    width?: string
    height?: string
    silent?: boolean
    fixed?: boolean
  }) {
    if (width) {
      this.style.maxWidth = width
      this.style.overflowX = 'auto'
    }

    if (height) {
      this.style.maxHeight = height
      this.style.overflowY = 'auto'
    }

    if (left === undefined && top === undefined && right === undefined && bottom === undefined) {
      this.style.transform = 'translateX(-50%) translateY(-50%)'
      this.style.left = '50%'
      this.style.top = '50%'
    } else if (fixed) {
      const bounding = this.parentElement?.getBoundingClientRect() || {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }

      this.style.position = 'fixed'

      if (left !== undefined) {
        left += bounding.left
        this.style.left = `${left}px`
      }
      if (top !== undefined) {
        top += bounding.top
        this.style.top = `${top}px`
      }
      if (right !== undefined) {
        right = window.innerWidth - (bounding.right - right)
        this.style.right = `${right}px`
      }
      if (bottom !== undefined) {
        bottom = window.innerHeight - (bounding.bottom - bottom)
        this.style.bottom = `${bottom}px`
      }
    } else {
      if (left !== undefined) {
        this.style.left = `${left}px`
      }
      if (top !== undefined) {
        this.style.top = `${top}px`
      }
      if (right !== undefined) {
        this.style.right = `${right}px`
      }
      if (bottom !== undefined) {
        this.style.bottom = `${bottom}px`
      }
    }

    this.setAttribute('active', '')

    requestAnimationFrame(() => {
      const vh = window.innerHeight
      const vw = window.innerWidth

      var bounding = this.getBoundingClientRect()

      var h = bounding.height
      var w = bounding.width
      var t = bounding.top
      var l = bounding.left

      // If the popup is too large, it will cause overflow scrolling.
      if (vh < h) {
        this.style.height = `${Math.min(Math.max(Math.floor((vh * 2) / 3), vh - (t + 20)), vh)}px`
        this.style.overflow = 'auto'
        h = vh
      }

      if (vw < w) {
        this.style.width = `${Math.min(Math.max(Math.floor((vw * 2) / 3), vw - (l + 20)), vw)}px`
        this.style.overflow = 'auto'
        w = vw
      }

      // To prevent pop-ups from crossing screen boundaries, use the
      const computedStyle = getComputedStyle(this)

      if (t < 0) {
        this.style.top = '10px'
        this.style.bottom = ''
      } else if (vh <= t + h) {
        this.style.top = ''
        this.style.bottom = '10px'
      }

      if (l < 0) {
        this.style.left = `calc(${computedStyle.left} - ${l - 10}px)`
        this.style.right = ''
      } else if (vw < l + w) {
        this.style.left = `calc(${computedStyle.left} - ${l + w - vw + 10}px)`
        this.style.right = ''
      }
    })

    // auto focusing
    !silent && this.guaranteeFocus()

    /* When the window is out of focus, all pop-ups should disappear. */
    window.addEventListener('blur', this._onwindowblur)
  }

  guaranteeFocus(target?: HTMLElement) {
    const focusible = (target || this).querySelector(
      ':scope > button, :scope > [href], :scope > input, :scope > select, :scope > textarea, :scope > [tabindex]:not([tabindex="-1"])'
    )

    if (focusible) {
      ;(focusible as HTMLElement).focus()
    } else {
      this.focus()
    }
  }

  /**
   * Closes the 'ox-popup'.
   */
  close() {
    this.removeAttribute('active')

    window.removeEventListener('blur', this._onwindowblur)

    if (this.removeAfterUse && this.parentElement) {
      /* this case is when the popup is opened by OxPopup.open(...) */
      this.removeEventListener('focusout', this._onfocusout)
      this.removeEventListener('keydown', this._onkeydown)
      this.removeEventListener('keyup', this._onkeyup)
      this.removeEventListener('click', this._onclick)
      this.removeEventListener('ox-close', this._onclose)
      this.removeEventListener('ox-collapse', this._oncollapse)
      this.removeEventListener('mouseup', this._onmouseup)
      this.removeEventListener('mousedown', this._onmousedown)
      this.removeEventListener('contextmenu', this._oncontextmenu)

      this.parentElement.removeChild(this)

      if (this.lastActive) {
        this.lastActive.focus && this.lastActive.focus()
      }
    }
  }
}
