import { LitElement, html, css, PropertyValues, nothing } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

@customElement('ox-split-pane')
export class OxSplitPane extends LitElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: var(--flex-direction, column);

      width: 100%;
      height: 100%;
    }

    #splitter {
      background-color: #ccc;
      cursor: var(--cursor-shape, col-resize);
    }

    #splitter {
      background-color: #ccc;
      cursor: var(--cursor-shape, col-resize);
      color: var(--md-sys-color-on-primary-container, #ccc);
    }

    :host([direction='row']) #splitter {
      height: 100%;
      width: 4px;
    }

    :host([direction='column']) #splitter {
      width: 100%;
      height: 4px;
    }

    ::slotted(*) {
      overflow: hidden;
    }

    slot[name='front']::slotted(*) {
      flex: var(--split-ratio, 0.5);
    }

    slot[name='back']::slotted(*) {
      flex: calc(1 - var(--split-ratio, 0.5));
    }
  `

  @property({ type: String, attribute: true }) direction: string = 'row'
  @property({ type: Number, attribute: true }) ratio: number = 0.5
  @property({ type: Boolean, attribute: true }) resizable: boolean = false

  @query('#splitter') splitter!: HTMLDivElement

  render() {
    return html`
      <slot name="front"></slot>
      ${this.resizable
        ? html`<div id="splitter" @mousedown=${this.startDragging} direction=${this.direction}></div>`
        : nothing}
      <slot name="back"></slot>
    `
  }

  async updated(changes: PropertyValues<this>) {
    if (changes.has('direction')) {
      this.style.setProperty('--flex-direction', this.direction)
      this.style.setProperty('--cursor-shape', this.direction == 'row' ? 'col-resize' : 'row-resize')
    }

    if (changes.has('ratio')) {
      this.style.setProperty('--split-ratio', String(this.ratio))
    }
  }

  startDragging(e: MouseEvent) {
    e.stopPropagation()
    e.preventDefault()

    document.addEventListener('mousemove', this.drag)
    document.addEventListener('mouseup', this.stopDragging)

    document.body.style.cursor = this.direction == 'row' ? 'col-resize' : 'row-resize'
  }

  drag = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    const { width, height, left, top } = this.getBoundingClientRect()

    const mouseX = e.clientX - left
    const mouseY = e.clientY - top

    if (this.direction == 'row') {
      const totalWidth = this.offsetWidth
      this.ratio = mouseX / width
    } else {
      const totalHeight = this.offsetHeight
      this.ratio = mouseY / height
    }
  }

  stopDragging = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    document.removeEventListener('mousemove', this.drag)
    document.removeEventListener('mouseup', this.stopDragging)

    document.body.style.cursor = 'auto'
  }
}
