import { css } from 'lit'

export const AUTH_STYLE_SIGN = css`
  :host {
    display: flex;
    background-color: var(--md-sys-color-primary);
    color: var(--md-sys-color-on-primary);

    --md-filled-text-field-supporting-text-color: var(--md-sys-color-on-primary);
  }

  :host *:focus {
    outline: none;
  }

  :host * {
    box-sizing: border-box;
  }

  .wrap {
    display: block;
    width: 450px;
    min-width: 350px;
    margin: 0 auto;
    padding-bottom: 100px;
    text-align: center;
  }

  .auth-brand {
    color: var(--md-sys-color-on-primary);
  }

  .auth-brand img {
    margin: 15% auto 5px auto;
    width: 100px;
    border: 3px solid var(--md-sys-color-on-primary);
    border-radius: 25px;
    box-shadow: var(--box-shadow);
  }
  .name {
    display: block;
    font: var(--auth-brand-name);
    text-shadow: var(--auth-brand-name-shadow);
  }
  .auth-brand .welcome-msg {
    font: var(--auth-brand-welcome-msg);
  }
  .auth-form {
    display: grid;
    grid-gap: var(--spacing-medium);
    grid-template-columns: 1fr 1fr;
  }

  form {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--spacing-medium);
    align-items: center;
  }

  h3 {
    grid-column: 1 / -1;
    margin: 50px 0 0 0;
    font: var(--auth-title-font);
    color: var(--auth-title-color, var(--md-sys-color-on-primary));
    text-transform: uppercase;
  }

  .field {
    grid-column: 1 / -1;
    text-align: left;
  }

  .submit-buttons-container {
    grid-column: 1 / -1;
    text-align: center;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  .fingerprint {
    color: var(--md-sys-color-on-primary);
    border: 1.5px solid var(--md-sys-color-on-primary);
    border-radius: 20%;
    width: 36px;
    height: 36px;
  }

  .field md-filled-text-field {
    grid-column: 1 / -1;
    width: 100%;
  }

  md-text-button,
  md-elevated-button {
    grid-column: 1 / -1;
    flex: 1;
  }

  .wrap .link {
    text-decoration: none;
    justify-self: flex-start;
  }

  .wrap .link md-text-button {
    --md-text-button-label-text-color: var(--md-sys-color-on-primary);
    --md-text-button-focus-label-text-color: var(--md-sys-color-on-primary);
    --md-text-button-hover-label-text-color: var(--md-sys-color-on-primary);
  }

  .wrap .link md-icon {
    color: var(--md-sys-color-on-primary);
  }

  #locale-area {
    display: flex;
    grid-column: 1 / -1;
    padding: 0 var(--spacing-medium);
  }

  #locale-area > label {
    display: flex;
    align-items: center;
    color: var(--md-sys-color-on-primary);
    --md-icon-size: 16px;
  }

  #locale-selector {
    font-size: 16px;
    width: 100%;
  }

  #locale-selector {
    --i18n-selector-field-border: none;
    --i18n-selector-field-background-color: none;
    --i18n-selector-field-font-size: 14px;
    --i18n-selector-field-color: var(--md-sys-color-on-primary);
  }

  .lottie-container {
    width: 100%;
    height: 300px;
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none;
  }
  .lottie-container lottie-player {
    position: absolute;
    bottom: -6%;
    width: 100%;
    height: auto;
  }

  @media (max-width: 450px) {
    .wrap {
      width: 85%;
      min-width: 320px;
    }
    .auth-form {
      grid-template-columns: 1fr;
    }
    .auth-brand img {
      margin: 12% auto 5px auto;
      width: 75px;
    }
    h3 {
      margin: 15px 0 0 0;
    }
    .lottie-container {
      overflow: hidden;
      height: 200px;
      pointer-events: none;
    }
    .lottie-container lottie-player {
      width: 1200px;
      left: -30%;
    }
  }

  @media screen and (min-width: 1400px) {
    .wrap {
      padding-bottom: 150px;
    }
  }
  @media screen and (min-width: 2500px) {
    .wrap {
      padding-bottom: 280px;
    }
  }
`
