import { TemplateResult } from 'lit'

import { PopupHandle, PopupOptions, setOpenPopupImplementation } from '@operato/popup'
import { store } from '@operato/shell'

export type Viewpart = {
  hovering?: 'center' | 'edge' | 'next'
  show?: boolean
  size?: string
  title?: string
  help?: string
  level?: VIEWPART_LEVEL
  closable?: boolean
  search?: {
    value?: string
    handler?: (closure: any, value: string) => void
    placeholder?: string
  }
  filter?: {
    handler?: (closure: any) => void
  }
  backdrop?: boolean
  temporary?: boolean /* auto remove */
  resizable?: boolean
  template?: TemplateResult
  position?: VIEWPART_POSITION
  templateProperties?: any
  zIndex?: number
}

export type AppendViewpartAction = {
  name: string
  viewpart: Viewpart
  position: VIEWPART_POSITION
}

export const appendViewpart = ({ name, viewpart, position }: AppendViewpartAction) => {
  store.dispatch({
    type: APPEND_VIEWPART,
    name,
    viewpart: {
      ...viewpart,
      show: viewpart.hovering && viewpart.show ? false : viewpart.show
    },
    position
  })

  if (viewpart.hovering && viewpart.show) {
    openOverlay(name)
  }
}

export const removeViewpart = (name: string) => {
  store.dispatch({
    type: REMOVE_VIEWPART,
    name
  })
}

export const updateViewpart = (name: string, override: any) => {
  store.dispatch({
    type: UPDATE_VIEWPART,
    name,
    override
  })
}

export const APPEND_VIEWPART = 'APPEND_VIEWPART'
export const REMOVE_VIEWPART = 'REMOVE_VIEWPART'
export const UPDATE_VIEWPART = 'UPDATE_VIEWPART'

export enum VIEWPART_POSITION {
  HEADERBAR = 'headerbar',
  NAVBAR = 'navbar',
  ASIDEBAR = 'asidebar',
  FOOTERBAR = 'footerbar',
  PAGE_HEADERBAR = 'page-headerbar',
  PAGE_FOOTERBAR = 'page-footerbar'
}

/**
 * 각 뷰파트 스택의 순서를 정하는 속성이다.
 * 뷰파트의 level 속성에 정의되도록 한다.
 * 맨 위/아래/좌측/우측에 보여져야하는 뷰파트는 TOPMOST 속성을 갖도록 하고,
 * 쌓인 순서대로 보여지는 뷰파트는 NORMAL 속성을 갖도록 한다.
 * default 속성은 NORMAL이다.
 */
export enum VIEWPART_LEVEL {
  TOPMOST = 'TOPMOST',
  NORMAL = 'NORMAL'
}

export enum TOOL_POSITION {
  FRONT_END = 'FRONT_END',
  FRONT = 'FRONT',
  CENTER = 'CENTER',
  REAR = 'REAR',
  REAR_END = 'REAR_END'
}

export const UPDATE_VIEWPORT_WIDTH = 'UPDATE_VIEWPORT_WIDTH'

export const updateLayout = (wide?: boolean) => {
  store.dispatch({
    type: UPDATE_VIEWPORT_WIDTH,
    width: wide ? 'WIDE' : 'NARROW'
  })
}

/* overlay navigation */
var overlaySequence = 0

export const openOverlay = (name: string, options?: any, silent?: boolean) => {
  var beforeState = history.state
  var beforeOverlay = beforeState ? beforeState.overlay : undefined
  var beforeSequence = !beforeOverlay || beforeOverlay.sequence === undefined ? overlaySequence : beforeOverlay.sequence
  var afterSequence = (overlaySequence = beforeSequence + 1)

  /* store의 layout의 내용을 변경한다. */
  if (!silent && options) {
    store.dispatch({
      type: UPDATE_VIEWPART,
      name,
      override: options
    })
  }

  /*
   * 현재 history.state를 확인하고, overlay의 이름이 같은
   * history에 추가하고 open 동작을 실행한다.
   */
  var afterState = Object.assign({}, beforeState || {}, {
    overlay: { name, sequence: afterSequence, escapable: options?.escapable !== false }
  })

  history.pushState(afterState, '', location.href)

  window.dispatchEvent(
    new CustomEvent('popstate', {
      detail: { state: afterState }
    })
  )
}

export const closeOverlay = (name: string) => {
  /*
   * 실제로 overlay를 close하는 작업은 window.onpopstate 핸들러에서 한다.
   */
  history.back()
}

export const toggleOverlay = (name: string, options: any) => {
  var { name: beforeOverlayName } = (history.state && history.state.overlay) || {}

  if (beforeOverlayName == name) {
    closeOverlay(name)
  } else {
    openOverlay(name, options)
  }
}

/*
 * popup handling
 *
 * popup은 overlay의 특별한 형태이다.
 * popup은 open될 때, viewpart를 append 하며, close 될 때 viewpart를 remove 한다.
 * - name: '$popup-${popupSequence}'
 * - position: VIEWPART_POSITION_HEADERBAR
 * - hovering: 'center' | 'next' | 'edge'
 */
var popupSequence = 0

/**
 * open popup in operato application environment
 *
 * @param {*} template html template to be rendered inside the popup
 * @param {PopupOptions} options
 * @returns popup handle object. This object is used to close the popup.
 */
export const openPopup = (template: TemplateResult, options: PopupOptions = {}): PopupHandle => {
  var name = `$popup-${popupSequence++}`

  appendViewpart({
    name,
    viewpart: {
      hovering: 'center',
      closable: true,
      ...options,
      backdrop: true,
      show: false,
      temporary: true /* auto remove */,
      template
    },
    position: VIEWPART_POSITION.HEADERBAR
  })

  openOverlay(name, options, true)

  var popup = {
    name,
    close: () => {
      /* 현재 overlay state를 확인해서, 자신인 경우에 history.back() 한다. */
      var state = history.state
      var overlay = (state || {}).overlay

      overlay && overlay.name == name && history.back()
    },
    closed: false
  } as PopupHandle

  document.addEventListener('overlay-closed', function listener(e) {
    if (name == (e as CustomEvent).detail) {
      popup.closed = true
      popup.onclosed && popup.onclosed()
      document.removeEventListener('overlay-closed', listener)
    }
  })

  return popup
}

setOpenPopupImplementation(openPopup)
