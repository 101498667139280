import { lazyReducerEnhancer, LazyStore } from 'pwa-helpers/lazy-reducer-enhancer.js'
import { Action, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux'
import thunk from 'redux-thunk'

import app from './reducers/app'
import route from './reducers/route'
import busy from './reducers/busy'

declare global {
  var __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
}

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// Only for providing test environment - combineReducers 에서 process.env.NODE_ENV를 접근하기 때문에.
if (typeof window.process == 'undefined') {
  window.process = {
    env: {
      NODE_ENV: JSON.stringify('development')
    }
  } as any
}

export const store: Store<unknown, Action<any>> & LazyStore = createStore(
  state => state,
  devCompose(lazyReducerEnhancer(combineReducers), applyMiddleware(thunk))
)

// Initially loaded reducers.
store.addReducers({
  app,
  route,
  busy
})
