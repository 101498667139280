/**
 * method to tell if platform of running browser is kind of touch device
 * @returns {boolean}
 */
export function isTouchDevice() {
  return window.matchMedia('(pointer:none), (pointer:coarse)').matches
}

/**
 * method to tell if platform of running browser is kind of mobile device
 * @returns {boolean}
 */
export function isHandheldDevice() {
  return window.matchMedia('(max-width: 460px)').matches
}

/**
 * method to tell if platform of running browser is kind of mobile device
 * @returns {boolean}
 */
export function isMobileDevice() {
  return window.matchMedia('(max-width: 767px)').matches
}

/**
 * method to tell if operating system of running browser is iOS
 * @returns {boolean}
 */
export function isIOS() {
  //@ts-ignore
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

/**
 * method to tell if operating system of running browser is MacOS
 * @returns {boolean}
 */
export function isMacOS() {
  return navigator.userAgent.indexOf('Mac') !== -1
}

/**
 * method to tell if platform of running browser is Safari
 * @returns {boolean}
 */
export function isSafari() {
  return !navigator.userAgent.match(/chrome|chromium|crios/i) && navigator.userAgent.match(/safari/i)
}
