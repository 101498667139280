/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { html, LitElement, css } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { i18next } from './config'
import { localize } from './localize'

@customElement('ox-i18n')
export class OxI18n extends localize(i18next)(LitElement) {
  @property({ type: String }) msgid!: string

  render() {
    return html`<span>${i18next.t(this.msgid)}</span>`
  }
}
