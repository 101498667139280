import { Action } from 'redux'

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

var snackbarTimer: number

export type NotificationLevel = 'info' | 'warn' | 'error'
export type Notification = {
  message: string
  level?: NotificationLevel
  option?: any
  ex?: any
}

export function notify(notification: string | Notification): void {
  if (typeof notification === 'string') {
    return notify({ message: notification })
  }

  var { message, level, option, ex } = notification as Notification

  document.dispatchEvent(
    new CustomEvent('notify', {
      detail: { message, level, ex, option }
    })
  )
}

export type SnackbarAction = {
  type: 'OPEN_SNACKBAR' | 'CLOSE_SNACKBAR'
  level?: NotificationLevel
  message?: string
  action?: any
}

export const showSnackbar =
  (
    level: NotificationLevel,
    {
      message,
      action,
      timer = 5000
    }: {
      message: string
      action?: any
      timer?: number
    }
  ) =>
  (dispatch: (param: SnackbarAction) => Action<any>) => {
    dispatch({
      type: OPEN_SNACKBAR,
      level,
      message,
      action
    })
    window.clearTimeout(snackbarTimer)

    if (timer != -1) snackbarTimer = window.setTimeout(() => dispatch({ type: CLOSE_SNACKBAR }), timer)
  }
