import startCase from 'lodash-es/startCase'
import { updateMetadata } from 'pwa-helpers/metadata.js'

import {
  REGISTER_NAVIGATION_CALLBACK,
  UNREGISTER_NAVIGATION_CALLBACK,
  UPDATE_ACTIVE_PAGE,
  UPDATE_CONTEXT,
  UPDATE_PAGE
} from '../actions/const'

const APP_TITLE_EL = document.querySelector('meta[name="application-name"]') as HTMLMetaElement

var appTitle: string | undefined
if (APP_TITLE_EL) {
  appTitle = APP_TITLE_EL.content
}

const INITIAL_STATE: {
  page: string
  resourceId: string
  params: any
  activePage: any
  context: any
  callbacks: any[]
} = {
  page: '',
  resourceId: '',
  params: {},
  activePage: null,
  context: {},
  callbacks: []
}

const route = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_PAGE:
      return {
        ...state,
        page: action.page,
        resourceId: action.resourceId,
        params: action.params
      }
    case UPDATE_CONTEXT:
      let title = action.context?.title
      let text = typeof title === 'object' ? title.text : title

      updateMetadata({
        title: appTitle + (text ? ` - ${startCase(text)}` : '')
      })
      return {
        ...state,
        context: action.context || (state.activePage && state.activePage.context) || {}
      }
    case UPDATE_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.activePage
      }

    case REGISTER_NAVIGATION_CALLBACK:
      return {
        ...state,
        callbacks: [...state.callbacks, action.callback]
      }
    case UNREGISTER_NAVIGATION_CALLBACK:
      return {
        ...state,
        callbacks: state.callbacks.filter(callback => callback !== action.callback)
      }

    default:
      return state
  }
}

export default route
