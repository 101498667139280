import '@material/web/icon/icon.js'

import { css, CSSResult, html, PropertyValues } from 'lit'
import { render } from 'lit-html'
import { customElement, property, query, state } from 'lit/decorators.js'
import Sortable from 'sortablejs'

import { OxPopup } from './ox-popup.js'
import { convertToFixedPosition } from './position-converter.js'

function guaranteeFocus(element: HTMLElement) {
  // 1. Give focus opportunity to the first focusable element within the option element.
  const focusible = element.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')

  if (focusible) {
    ;(focusible as HTMLElement).focus()
    return
  }

  // 2. Give focus opportunity to the closest parent, including itself.
  const closest = element.closest(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  ) as HTMLElement

  closest?.focus()
}

/**
 * A custom element representing a list-like popup menu.
 */
@customElement('ox-popup-list')
export class OxPopupList extends OxPopup {
  static styles = [
    ...OxPopup.styles,
    css`
      :host {
        display: none;
        align-items: stretch;
        background-color: var(--ox-popup-list-background-color, var(--md-sys-color-surface-container-lowest));
        color: var(--ox-popup-list-color, var(--md-sys-color-on-surface));
        z-index: 100;
        box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.15);
        padding: var(--spacing-small) 0;
        border-radius: var(--md-sys-shape-corner-small, 5px);

        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      }

      :host([active]) {
        display: flex;
        flex-direction: column;
      }

      :host(*:focus) {
        outline: none;
      }

      :host([nowrap]) ::slotted([option]) {
        white-space: nowrap;
      }

      ::slotted([option]) {
        border-left: 3px solid transparent;
      }

      ::slotted(*) {
        padding: var(--spacing-medium);
        border-bottom: 1px solid var(--md-sys-color-surface-variant);
        cursor: pointer;
        outline: none;
        color: var(--ox-popup-list-color, var(--md-sys-color-on-surface-variant));
      }

      ::slotted(*:focus) {
        outline: none;
      }

      ::slotted([option][active]),
      ::slotted([option]:hover) {
        background-color: var(--ox-popup-list-background-color-variant, var(--md-sys-color-surface-variant));
        color: var(--ox-popup-list-color-variant, var(--md-sys-color-on-surface-variant));
      }

      ::slotted([option][selected]) {
        border-left: 3px solid var(--md-sys-color-primary);
        font-weight: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500));
      }

      ::slotted([separator]) {
        height: 1px;
        width: 100%;
        padding: 0;
        background-color: var(--ox-popup-menu-separator-color, var(--md-sys-color-surface-variant));
      }

      ::slotted([hidden]) {
        display: none;
      }

      [search] {
        display: flex;
        position: relative;
        align-items: center;
        padding: var(--spacing-small) var(--spacing-medium);

        --md-icon-size: var(--icon-size-small);
      }

      [search] [type='text'] {
        flex: 1;
        background-color: transparent;
        border: 0;
        padding: 0 0 0 var(--spacing-huge);
        outline: none;
        width: 50px;
      }

      [search] md-icon {
        color: var(--md-sys-color-secondary);
      }

      [search] md-icon[search-icon] {
        position: absolute;
      }

      [search] md-icon[delete-icon] {
        opacity: 0.5;
        --md-icon-size: var(--icon-size-tiny);
      }

      [nothing] {
        opacity: 0.5;
        text-align: center;
      }

      div[body] {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        overflow: auto;
      }
    `
  ]

  /**
   * A boolean property that, when set to true, allows multiple options to be selected in the popup list.
   * @type {boolean}
   */
  @property({ type: Boolean, attribute: true, reflect: true }) multiple: boolean = false

  /**
   * An optional attribute that specifies the name of the attribute used to mark selected options in the list.
   * @type {string|undefined}
   */
  @property({ type: String, attribute: 'attr-selected', reflect: true }) attrSelected?: string

  /**
   * A boolean property that, when set to true, enables the search functionality in the popup list.
   * Users can search/filter options by typing in a search bar.
   * @type {boolean|undefined}
   */
  @property({ type: Boolean, attribute: 'with-search', reflect: true }) withSearch?: boolean

  /**
   * A boolean property that, when set to true, enables the drag-and-drop sorting functionality within the popup list.
   * This allows users to reorder the options in the list by dragging them into new positions.
   * @type {boolean|undefined}
   */
  @property({ type: Boolean, attribute: 'sortable', reflect: true }) sortable?: boolean = false

  /**
   * The value(s) of the selected option(s) in the popup list.
   * This property can be a string or an array of strings, depending on whether multiple selections are allowed.
   * @type {string|string[]|undefined}
   */
  @property({ type: String }) value?: string | string[]

  @state() activeIndex?: number
  @state() searchKeyword?: string
  @state() nothingToSelect: boolean = false

  @query('[search] input') searchInput!: HTMLInputElement
  @query('div[body]') body!: HTMLDivElement

  private sortableObject?: Sortable
  private locked: boolean = false

  render() {
    return html`
      <slot name="header"> </slot>

      ${this.withSearch
        ? html`
            <label search for="search" @input=${(e: InputEvent) => this._oninputsearch(e)}>
              <md-icon search-icon>search</md-icon>
              <input
                id="search"
                type="text"
                autocomplete="off"
                @keydown=${(e: KeyboardEvent) => this._onkeydownsearch(e)}
                @change=${(e: InputEvent) => this._onchangesearch(e)}
              />
              <md-icon
                @click=${() => {
                  this.searchInput.value = ''
                  this.searchKeyword = ''
                }}
                delete-icon
                >delete</md-icon
              >
            </label>
          `
        : html``}

      <div body>
        <slot
          @change=${(e: Event) => {
            e.stopPropagation()
          }}
        >
        </slot>
      </div>

      ${this.nothingToSelect ? html`<label nothing>nothing to select</label>` : html``}
    `
  }

  protected _oninputsearch(e: InputEvent) {
    e.stopPropagation()
    e.preventDefault()

    this.searchKeyword = (e.target as HTMLInputElement).value
  }

  protected _onchangesearch(e: InputEvent) {
    e.stopPropagation()
    this.searchKeyword = (e.target as HTMLInputElement).value
  }

  protected _onkeydownsearch(e: KeyboardEvent) {
    const keys = ['Esc', 'Escape', 'Up', 'ArrowUp', 'Down', 'ArrowDown']
    if (!keys.includes(e.key)) {
      e.stopPropagation()
    }
  }

  protected _onkeydown: (e: KeyboardEvent) => void = function (this: OxPopupList, e: KeyboardEvent) {
    e.stopPropagation()

    switch (e.key) {
      case 'Esc': // for IE/Edge
      case 'Escape':
        this.close()
        break

      case 'Left': // for IE/Edge
      case 'ArrowLeft':
      case 'Up': // for IE/Edge
      case 'ArrowUp':
        this.activeIndex!--
        break

      case 'Right': // for IE/Edge
      case 'ArrowRight':
      case 'Down': // for IE/Edge
      case 'ArrowDown':
        this.activeIndex!++
        break

      case 'Enter':
      case ' ':
      case 'Spacebar': // for old firefox
        this.setActive(this.activeIndex!, true)
        this.select()
        break
    }
  }.bind(this)

  protected _onfocusout: (e: FocusEvent) => void = function (this: OxPopupList, e: FocusEvent) {
    const to = e.relatedTarget as HTMLElement

    if (!this.contains(to)) {
      /* If the focus has clearly moved to an element outside of my range, the ox-popup-list should be closed. */
      // @ts-ignore for debug
      !this.preventCloseOnBlur && !this.debug && !window.POPUP_DEBUG && this.close()
    }
  }.bind(this)

  protected _onclick: (e: MouseEvent) => void = function (this: OxPopupList, e: MouseEvent) {
    e.stopPropagation()

    // Check if the click event target is a checkbox
    if ((e.target as HTMLElement).closest('input[type="checkbox"]')) {
      return // Do not proceed if it's a checkbox click
    }

    const option = (e.target as HTMLElement)?.closest('[option]')
    if (option) {
      this.setActive(option, true)
      this.select()
    }
  }.bind(this)

  updated(changes: PropertyValues<this>) {
    if (changes.has('activeIndex')) {
      this.activeIndex !== undefined && this.setActive(this.activeIndex)
    }

    if (changes.has('sortable')) {
      this.sortableObject && this.sortableObject.destroy()

      if (this.sortable) {
        this.sortableObject = Sortable.create(this, {
          handle: '[option]',
          draggable: '[option]',
          direction: 'vertical',
          animation: 150,
          touchStartThreshold: 10,
          onEnd: e => {
            this.locked = false
            this.dispatchEvent(
              new CustomEvent('sorted', {
                detail: Array.from(this.querySelectorAll('[option]'))
              })
            )
          },
          onMove: e => {
            // Check if the drag event target is a checkbox
            if ((e.dragged as HTMLElement).querySelector('input[type="checkbox"]')) {
              return false // Prevent sorting if it's a checkbox drag
            }
            this.locked = true
          }
        })
      }
    }

    if (changes.has('searchKeyword')) {
      const attrSelected = this.attrSelected || 'selected'
      this.querySelectorAll(`[option]`).forEach(item => {
        if (!this.searchKeyword || item.textContent?.match(new RegExp(this.searchKeyword, 'i'))) {
          item.removeAttribute('hidden')
        } else {
          item.removeAttribute('selected')
          item.setAttribute('hidden', '')
        }
      })
      this.nothingToSelect = this.querySelectorAll(`[option]:not([hidden])`).length === 0
    }

    if (changes.has('value')) {
      const options = Array.from(this.querySelectorAll(':scope > [option]'))

      var values = this.value
      if (!(values instanceof Array)) {
        values = [values as string]
      }

      options.forEach(option => {
        if (values?.includes((option as HTMLElement).getAttribute('value') || '')) {
          option.setAttribute(this.attrSelected || 'selected', '')
        } else {
          option.removeAttribute(this.attrSelected || 'selected')
        }
      })
    }
  }

  /**
   * Retrieves the labels of the selected options in the popup list.
   * If multiple selections are allowed, an array of labels is returned. Otherwise, a single label is returned.
   * @returns {string|string[]} The label(s) of the selected option(s).
   */
  public getSelectedLabels(): string | string[] {
    const options = Array.from(this.querySelectorAll(':scope > [option]'))

    const selected = options
      .filter(option => option.hasAttribute('value') && option.hasAttribute(this.attrSelected || 'selected'))
      .map(option => option.textContent || '')

    return this.multiple ? selected : selected[0]
  }

  /**
   * Handles the selection of options in the popup list and dispatches a 'select' event with the selected value(s).
   * If multiple selections are allowed, an array of selected values is dispatched; otherwise, a single value is dispatched.
   * Also, it checks whether the selected option should remain alive and whether the popup should be closed.
   */
  async select() {
    await this.updateComplete

    const options = Array.from(this.querySelectorAll(':scope > [option]'))

    const selected = options
      .filter(option => option.hasAttribute('value') && option.hasAttribute(this.attrSelected || 'selected'))
      .map(option => option.getAttribute('value'))

    this.dispatchEvent(
      new CustomEvent('select', {
        bubbles: true,
        composed: true,
        detail: this.multiple ? selected : selected[0]
      })
    )

    const option = options[this.activeIndex!]
    if (!option.hasAttribute('alive-on-select') && !this.hasAttribute('multiple')) {
      this.close()
    }
  }

  /**
   * Sets the active option within the popup list based on the given index or Element.
   * If 'withSelect' is true, it also manages the selection state of the option.
   *
   * @param {number | Element | null} active - The index or Element of the option to set as active.
   * @param {boolean | undefined} withSelect - Indicates whether to manage the selection state of the option.
   */
  setActive(active: number | Element | null, withSelect?: boolean) {
    var options = Array.from(this.querySelectorAll('[option]:not([hidden])'))
    if (this.withSearch) {
      options.push(this.renderRoot.querySelector('[search]')!)
    }

    if (active instanceof Element) {
      const index = options.findIndex(option => option === active)
      this.setActive(index === -1 ? 0 : index, withSelect)
      return
    }

    const attrSelected = this.attrSelected || 'selected'

    options.forEach(async (option, index) => {
      if (typeof active === 'number' && index === (active + options.length) % options.length) {
        option.setAttribute('active', '')

        if (withSelect && !this.attrSelected) {
          /* being set attribute attrs-selected means, that element should know how to do when event happened. */
          this.multiple ? option.toggleAttribute('selected') : option.setAttribute('selected', '')
        }

        guaranteeFocus(option as HTMLElement)

        this.activeIndex = index
      } else {
        option.removeAttribute('active')
        /* even thought attribute attrs-selected set, ox-popup-list have to unset others. */
        !this.multiple && withSelect && option.removeAttribute(attrSelected)
      }
    })
  }

  /**
   * Overrides the 'open' method of the base class 'OxPopup' to set the initial active index
   * when the popup list is opened. It ensures that an option is initially selected for user interaction.
   *
   * @param {object} params - The parameters for opening the popup, including position and size.
   */
  override open(params: {
    left?: number
    top?: number
    right?: number
    bottom?: number
    width?: string
    height?: string
    silent?: boolean
    // fixed?: boolean
  }) {
    super.open(params)

    if (this.activeIndex === undefined) {
      const activeElement = this.querySelector(`[${this.attrSelected || 'selected'}]`)
      this.setActive(activeElement || 0)
    } else {
      this.setActive(this.activeIndex)
    }
  }

  /**
   * Overrides the 'close' method of the base class 'OxPopup' to dispatch a custom event
   * indicating that the popup list is being closed. This event can be used for further interactions
   * or logic in the application.
   */
  override close() {
    if (this.locked) {
      return
    }

    if (this.hasAttribute('active')) {
      this.dispatchEvent(
        new CustomEvent('close', {
          bubbles: true,
          composed: true
        })
      )
    }

    super.close()
  }

  /**
   * Open OxPopup
   *
   * @param {PopupOpenOptions}
   */
  static open({
    template,
    top,
    left,
    right,
    bottom,
    parent,
    multiple,
    sortable,
    attrSelected,
    styles,
    debug
  }: {
    template: unknown
    top?: number
    left?: number
    right?: number
    bottom?: number
    parent?: Element | null
    multiple?: boolean
    sortable?: boolean
    debug?: boolean
    attrSelected?: string
    styles?: CSSResult
  }): OxPopupList {
    const target = document.createElement('ox-popup-list') as OxPopupList

    if (styles) {
      const style = document.createElement('style')
      style.textContent = styles.cssText

      const shadow = target.attachShadow({ mode: 'open' })
      shadow.appendChild(style)
    }

    if (!!debug) {
      target.setAttribute('debug', '')
    }

    if (!!multiple) {
      target.setAttribute('multiple', '')
    }

    if (!!sortable) {
      target.setAttribute('sortable', '')
    }

    if (attrSelected) {
      target.setAttribute('attr-selected', attrSelected)
    }

    render(template, target)

    if (parent) {
      var { left, top, right, bottom } = convertToFixedPosition({
        left,
        top,
        right,
        bottom,
        relativeElement: parent as HTMLElement
      })
    }

    document.body.appendChild(target)
    target.removeAfterUse = true
    target.open({ top, left, right, bottom })

    return target
  }
}
