import { customElement } from 'lit/decorators.js'

import { AbstractSign } from '../../components/abstract-sign'

@customElement('auth-signin')
export class AuthSignin extends AbstractSign {
  get pageName() {
    return 'sign in'
  }

  get actionUrl() {
    return '/auth/signin'
  }

  get autocompletable() {
    return true
  }
}
