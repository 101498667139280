import { ReactiveController, ReactiveControllerHost } from 'lit'
import { hasOverflow } from '../has-overflow'

export class TooltipReactiveController implements ReactiveController {
  private host: ReactiveControllerHost & HTMLElement
  private attributeName: string
  private content?: string | ((element: HTMLElement) => string)
  private static tooltipContainer: HTMLElement | null = null

  constructor(
    host: ReactiveControllerHost & HTMLElement,
    options?: {
      content?: string | ((element: HTMLElement) => string)
      attributeName?: string
    }
  ) {
    const { attributeName, content } = options || {}

    this.host = host
    this.attributeName = attributeName || 'data-reactive-tooltip'
    this.content = content

    host.addController(this)

    // Create a tooltip container element if it doesn't exist
    if (!TooltipReactiveController.tooltipContainer) {
      TooltipReactiveController.tooltipContainer = document.createElement('div')
      TooltipReactiveController.tooltipContainer.className = 'ox-tooltip-container'
      TooltipReactiveController.tooltipContainer.style.position = 'fixed'
      TooltipReactiveController.tooltipContainer.style.pointerEvents = 'none'
      TooltipReactiveController.tooltipContainer.style.zIndex = '1000'
      // Set default styles using CSS variables
      TooltipReactiveController.tooltipContainer.style.fontSize = 'var(--tooltip-font-size, 1.0em)'
      TooltipReactiveController.tooltipContainer.style.color = 'var(--tooltip-color, #fff)'
      TooltipReactiveController.tooltipContainer.style.backgroundColor =
        'var(--tooltip-background-color, rgba(0, 0, 0, 0.7))'
      TooltipReactiveController.tooltipContainer.style.padding = 'var(--tooltip-padding, 5px)'
      TooltipReactiveController.tooltipContainer.style.borderRadius = 'var(--tooltip-border-radius, 3px)'
      TooltipReactiveController.tooltipContainer.style.boxShadow =
        'var(--tooltip-box-shadow, 0 2px 10px rgba(0, 0, 0, 0.2))'
      TooltipReactiveController.tooltipContainer.style.maxWidth = 'var(--tooltip-max-width, 300px)'
      TooltipReactiveController.tooltipContainer.style.wordWrap = 'break-word'
      TooltipReactiveController.tooltipContainer.style.whiteSpace = 'pre-wrap'
      TooltipReactiveController.tooltipContainer.style.overflow = 'hidden'
      document.body.appendChild(TooltipReactiveController.tooltipContainer)
    }
  }

  hostConnected() {
    const target = this.host.shadowRoot ?? this.host
    target.addEventListener('mouseover', this.handleMouseOver)
    target.addEventListener('mouseout', this.handleMouseOut)
  }

  hostDisconnected() {
    const target = this.host.shadowRoot ?? this.host
    target.removeEventListener('mouseover', this.handleMouseOver)
    target.removeEventListener('mouseout', this.handleMouseOut)
  }

  private handleMouseOver = (e: Event) => {
    const element = e.target as HTMLElement
    if (element.hasAttribute(this.attributeName) && hasOverflow(element)) {
      const tooltipContent =
        typeof this.content === 'function'
          ? this.content.call(this, element)
          : this.content || element.textContent?.trim() || ''

      const rect = element.getBoundingClientRect()

      // Calculate the fixed position
      const fixedTop = rect.top + rect.height
      const fixedLeft = rect.left

      // Set tooltip container position and content
      TooltipReactiveController.tooltipContainer!.style.top = `${fixedTop}px`
      TooltipReactiveController.tooltipContainer!.style.left = `${fixedLeft}px`
      TooltipReactiveController.tooltipContainer!.style.display = 'block'
      TooltipReactiveController.tooltipContainer!.textContent = tooltipContent
    }
  }

  private handleMouseOut = (e: Event) => {
    if (TooltipReactiveController.tooltipContainer) {
      TooltipReactiveController.tooltipContainer.style.display = 'none'
    }
  }
}
