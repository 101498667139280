import { TimeCapsule } from './timecapsule'
import debounce from 'lodash-es/debounce'

/**
 * A function that takes a SnapshotTaker as an argument and initiates snapshot creation
 * using a debouncer to delay the process.
 * @param {SnapshotTaker} taker - The SnapshotTaker instance to trigger snapshots for.
 */
var debouncer = debounce(
  taker => {
    if (!taker.brake) {
      taker.take()
    }
  },
  1000,
  { leading: true, trailing: false }
)

/**
 * Function to trigger snapshot creation for a SnapshotTaker instance.
 * Checks if the taker is in a brake state or not dirty before triggering the snapshot.
 * @param {SnapshotTaker} taker - The SnapshotTaker instance to create a snapshot for.
 */
function take_snapshot(taker: SnapshotTaker) {
  if (taker.brake || !taker.dirty) return
  debouncer(taker)
}

/**
 * Type definition for holding state information.
 * @typedef {Object} StateHolder
 * @property {*} state - The state to be held.
 */
export type StateHolder = {
  state: any
}

/**
 * Class responsible for taking and managing snapshots of state.
 */
export class SnapshotTaker {
  private _brake: boolean = false

  dirty: boolean = false

  state_holder?: StateHolder
  timecapsule?: TimeCapsule

  /**
   * Creates an instance of SnapshotTaker.
   * @param {*} state_holder - The object that holds the state to be snapshot.
   * @param {TimeCapsule} timecapsule - The TimeCapsule instance associated with this SnapshotTaker.
   */
  constructor(state_holder: any, timecapsule: TimeCapsule) {
    this.state_holder = state_holder
    this.timecapsule = timecapsule
    this.timecapsule.snapshot_taker = this
  }

  /**
   * Disposes of the SnapshotTaker and clears associated references.
   */
  dispose() {
    delete this.state_holder
    delete this.timecapsule
  }

  /**
   * Marks the SnapshotTaker as dirty and initiates snapshot creation.
   */
  touch() {
    this.dirty = true
    take_snapshot(this)
  }

  /* 모든 조건에 관계없이 현재 상태를 snapshot으로 취한다. */
  /**
   * Takes a snapshot of the current state.
   * @param {boolean} [force=false] - If true, the snapshot is taken even if not dirty.
   */
  take(force: boolean = false) {
    if (this.dirty || force) {
      this.timecapsule?.snapshot(this.state_holder?.state)
      this.dirty = false
    }
  }

  /**
   * Gets the brake state of the SnapshotTaker.
   * @returns {boolean} - true if the brake is active, false otherwise.
   */
  get brake() {
    return this._brake
  }

  /* 마우스를 드래깅하는 동안, 보통 brake 를 ON 시킨다. */
  /**
   * Sets the brake state of the SnapshotTaker and initiates snapshot creation.
   * @param {boolean} brake - The new brake state.
   */
  set brake(brake) {
    this._brake = !!brake
    take_snapshot(this)
  }
}
