import { REGISTER_NAVIGATION_CALLBACK, store } from '@operato/shell'
import { UPDATE_VIEWPART, closeOverlay, openOverlay, openPopup, toggleOverlay } from './actions/layout'

import layout from './reducers/layout'
import { showSnackbar } from './actions/snackbar'
import snackbar from './reducers/snackbar'

store.addReducers({
  layout,
  snackbar
})

document.addEventListener('open-overlay', event => {
  const { name, options } = (event as CustomEvent).detail
  openOverlay(name, options)
})

document.addEventListener('close-overlay', event => {
  const { name } = (event as CustomEvent).detail
  closeOverlay(name)
})

document.addEventListener('toggle-overlay', event => {
  const { name, options } = (event as CustomEvent).detail
  toggleOverlay(name, options)
})

document.addEventListener('open-popup', event => {
  const { template, options, callback } = (event as CustomEvent).detail
  var popup = openPopup(template, options)
  if (popup && callback) callback(popup)
})

document.addEventListener('notify', event => {
  let { message, level, ex = '', option = {} } = (event as CustomEvent).detail

  switch (level) {
    case 'error':
      console.error(message, ex)
      break
    case 'warn':
      console.warn(message, ex)
      break
    case 'info':
      console.info(message)
      break
    default:
      break
  }

  store.dispatch(
    showSnackbar(level, {
      message,
      ...(option as {
        action?: any
        timer?: number
      })
    }) as any
  )
})

/* overlay handling */
var overlayStack: any[] = []
function getLastSequence() {
  return overlayStack.length > 0 ? overlayStack[overlayStack.length - 1].overlay.sequence : -1
}

document.addEventListener('keydown', event => {
  if (overlayStack.length > 0 && (event.key === 'Escape' || event.key === 'Esc')) {
    history.state?.overlay?.escapable && history.back()
  }
})

const historyHandler = (location: string, event: Event) => {
  var navigated = event instanceof PopStateEvent

  var state = history.state
  var overlay = state?.overlay
  var sequence = overlay?.sequence || -1

  var lastSequence = getLastSequence()

  if (overlayStack.length > 0 && sequence < lastSequence) {
    /* overlay 관련 history가 아닌 경우. */
    do {
      let { overlay } = overlayStack.pop()
      store.dispatch({
        type: UPDATE_VIEWPART,
        name: overlay.name,
        override: { show: false }
      })

      lastSequence = getLastSequence()
    } while (sequence < lastSequence)
  }

  if (!navigated && overlay) {
    overlayStack.push({ ...state })

    store.dispatch({
      type: UPDATE_VIEWPART,
      name: overlay.name,
      override: { show: true }
    })
  }
}

store.dispatch({
  type: REGISTER_NAVIGATION_CALLBACK,
  callback: historyHandler
})

export default store
