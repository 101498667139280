import { ApolloLink } from '@apollo/client/core'

var activeRequests = 0

var callback: ((activeRequestCount: number) => void) | null = null

export const activeRequestCounterLink = new ApolloLink((operation, forward) => {
  callback && callback(++activeRequests)

  return forward(operation).map(response => {
    callback && callback(--activeRequests)

    return response
  })
})

export function setActiveRequestCounterCallback(newCallback: (activeRequestCount: number) => void) {
  callback = newCallback
}

export function resetActiveRequestCounterCallback() {
  callback = null
}

export function decreaseActiveRequestCounter() {
  callback && callback(--activeRequests)
}

export function increaseActiveRequestCounter() {
  callback && callback(++activeRequests)
}
