import { css } from 'lit'

export const CommonGristStyles = css`
  :host {
    display: flex;

    width: 100%;
  }

  ox-grist {
    flex: 1;
    overflow-y: auto;
  }

  [slot='headroom'] {
    display: flex;
    flex-direction: row;
    gap: 0 10px;
    align-items: center;
    padding: var(--spacing-small) var(--spacing-medium);

    --md-icon-size: var(--icon-size-medium);
  }

  [slot='headroom']:empty {
    padding: 0;
  }

  #modes {
    display: flex;
    gap: var(--spacing-small);
  }

  #sorters md-icon,
  #modes md-icon {
    --md-icon-size: var(--icon-size-small);
  }

  [slot='setting'] {
    position: absolute;
    top: -3px;
    right: 0;
  }

  #sorters {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: auto;
    margin-right: var(--spacing-small);
    padding-left: var(--spacing-tiny);
    border-bottom: var(--md-sys-color-outline);
    position: relative;
    color: var(--md-sys-color-on-secondary-container);
    font-size: var(--fontsize-default);
    user-select: none;
  }

  #sorters > * {
    padding: var(--spacing-tiny);
    vertical-align: middle;
  }

  #modes > * {
    padding: var(--spacing-tiny);
    opacity: 0.5;
    color: var(--md-sys-color-on-primary-container);
    cursor: pointer;
  }

  #modes > md-icon[active] {
    border-radius: 9px;
    background-color: var(--md-sys-color-surface-variant);
    opacity: 1;
    color: var(--md-sys-color-primary);
    cursor: default;
  }

  #modes > md-icon:hover {
    opacity: 1;
    color: var(--md-sys-color-on-secondary-container);
  }

  #add {
    text-align: right;
  }

  #add button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 10px;

    background-color: var(--md-sys-color-tertiary);
    color: var(--md-sys-color-on-tertiary);
    border: 0;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  #add button:hover {
    background-color: var(--md-sys-color-surface-tint);
    color: var(--md-sys-color-on-primary);
    box-shadow: var(--box-shadow);
  }

  #add button md-icon {
    font-size: 1.5em;
  }

  #filters {
    flex: 1;

    display: flex;
    align-items: center;
  }

  #filters > * {
    margin-right: var(--spacing-small);
  }

  @media only screen and (max-width: 460px) {
    [slot='headroom'] {
      box-shadow: var(--box-shadow);
      padding: 0;
    }

    #modes {
      display: none;
    }
  }
`
