interface PositionOptions {
  left?: number
  top?: number
  right?: number
  bottom?: number
  relativeElement: HTMLElement
}

interface FixedPosition {
  left?: number
  top?: number
  right?: number
  bottom?: number
}

export function convertToFixedPosition({ left, top, right, bottom, relativeElement }: PositionOptions): FixedPosition {
  // Get bounding rectangle of the relative element
  const rect = relativeElement.getBoundingClientRect()

  // Get the viewport dimensions
  const viewportHeight = window.innerHeight
  const viewportWidth = window.innerWidth

  // Calculate fixed positions
  const fixedLeft = left !== undefined ? rect.left + left : undefined
  const fixedTop = top !== undefined ? rect.top + top : undefined
  const fixedRight = right !== undefined ? viewportWidth - rect.right + right : undefined
  const fixedBottom = bottom !== undefined ? viewportHeight - rect.bottom + bottom : undefined

  // Return the new fixed position values
  return {
    left: fixedLeft,
    top: fixedTop,
    right: fixedRight,
    bottom: fixedBottom
  }
}
