import { css, html, PropertyValues } from 'lit'
import { render } from 'lit-html'
import { customElement, property } from 'lit/decorators.js'

import { OxPopup } from './ox-popup'
import { convertToFixedPosition } from './position-converter.js'

function focusClosest(element: HTMLElement) {
  /* Find the closest focusable element. */
  const closest = element.closest(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  ) as HTMLElement

  closest?.focus()

  return closest
}

/**
 * Custom element representing a popup menu. It extends OxPopup.
 */
@customElement('ox-popup-menu')
export class OxPopupMenu extends OxPopup {
  static styles = [
    ...OxPopup.styles,
    css`
      :host {
        display: none;
        flex-direction: column;
        align-items: stretch;
        background-color: var(--ox-popup-menu-background-color, var(--md-sys-color-surface));
        color: var(--ox-popup-list-color, var(--md-sys-color-on-surface));
        z-index: 100;
        box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.15);
        padding: var(--spacing-small) 0;
        border-radius: var(--spacing-small);

        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      }

      :host([active]) {
        display: flex;
      }

      :host(*:focus) {
        outline: none;
      }

      ::slotted(*) {
        padding: var(--spacing-medium);
        border-bottom: 1px solid var(--md-sys-color-surface-variant);
        cursor: pointer;
        color: var(--ox-popup-list-color, var(--md-sys-color-outline-variant));
      }

      ::slotted(*:focus) {
        cursor: pointer;
        outline: none;
      }

      ::slotted([menu]),
      ::slotted(ox-popup-menuitem) {
        border-left: 3px solid transparent;
        background-color: var(--ox-popup-menu-background-color, var(--md-sys-color-surface));
        color: var(--ox-popup-menu-color, var(--md-sys-color-on-surface));
      }

      ::slotted([menu][active]),
      ::slotted([menu]:hover),
      ::slotted(ox-popup-menuitem[active]),
      ::slotted(ox-popup-menuitem:hover) {
        background-color: var(--ox-popup-list-background-color-variant, var(--md-sys-color-surface-variant));
        color: var(--ox-popup-list-color-variant, var(--md-sys-color-on-surface-variant));
      }

      ::slotted(ox-popup-menuitem[active]) {
        border-left: 3px solid var(--md-sys-color-primary);
        font-weight: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500));
      }

      ::slotted([separator]) {
        height: 1px;
        width: 100%;
        padding: 0;
        background-color: var(--ox-popup-menu-separator-color, var(--md-sys-color-surface-variant));
      }
    `
  ]

  /**
   * Property to track the index of the active menu item.
   */
  @property({ type: Number }) activeIndex: number = 0

  render() {
    return html` <slot> </slot> `
  }

  protected _onkeydown: (e: KeyboardEvent) => void = function (this: OxPopupMenu, e: KeyboardEvent) {
    e.stopPropagation()

    switch (e.key) {
      case 'Esc': // for IE/Edge
      case 'Escape':
      case 'Left': // for IE/Edge
      case 'ArrowLeft':
        this.close()
        break

      case 'Up': // for IE/Edge
      case 'ArrowUp':
        this.activeIndex--
        break

      case 'Right': // for IE/Edge
      case 'ArrowRight':
      case 'Down': // for IE/Edge
      case 'ArrowDown':
        this.activeIndex++
        break

      case 'Enter':
        e.stopPropagation()
        var menu = (e.target as HTMLElement)?.closest('[menu], ox-popup-menuitem')
        if (menu) {
          this.select(menu)
        }
        break
    }
  }.bind(this)

  protected _onfocusout: (e: FocusEvent) => void = function (this: OxPopupMenu, e: FocusEvent) {
    const target = e.target as HTMLElement
    const to = e.relatedTarget as HTMLElement
    const from = target.closest('ox-popup-menu')

    if (!to && from !== this) {
      e.stopPropagation()

      /* "하위의 POPUP-MENU 엘리먼트가 포커스를 잃었지만, 그 포커스를 받은 엘리먼트가 없다."는 의미는 그 서브메뉴가 클로즈된 것을 의미한다. */
      this.setActive(this.activeIndex)
    } else {
      if (!this.contains(to)) {
        /* 분명히 내 범위가 아닌 엘리먼트로 포커스가 옮겨졌다면, popup-menu는 닫혀야 한다. */
        // @ts-ignore for debug
        !window.POPUP_DEBUG && this.close()
      }
    }
  }.bind(this)

  protected _onclick: (e: MouseEvent) => void = function (this: OxPopupMenu, e: MouseEvent) {
    e.stopPropagation()

    const menu = (e.target as HTMLElement)?.closest('[menu], ox-popup-menuitem')
    if (menu) {
      this.setActive(menu)
      this.select(menu)
    }
  }.bind(this)

  updated(changes: PropertyValues<this>) {
    if (changes.has('activeIndex')) {
      this.setActive(this.activeIndex)
    }
  }

  /**
   * Selects a menu item by dispatching a 'select' event.
   * Closes the menu if the item doesn't have 'alive-on-select' attribute.
   */
  select(menu: Element) {
    menu.dispatchEvent(new CustomEvent('select'))
    if (!menu.hasAttribute('alive-on-select')) {
      this.dispatchEvent(new CustomEvent('ox-close', { bubbles: true, composed: true, detail: this }))
    }
  }

  /**
   * Sets the active menu item based on the index or the menu element itself.
   */
  setActive(active: number | Element | null) {
    const menus = Array.from(this.querySelectorAll(':scope > ox-popup-menuitem, :scope > [menu]'))

    menus.map(async (menu, index) => {
      if (typeof active === 'number' && index === (active + menus.length) % menus.length) {
        menu.setAttribute('active', '')
        focusClosest(menu as HTMLElement)

        this.activeIndex = index
      } else if (active === menu) {
        if (this.activeIndex === index) {
          /* 메뉴의 update를 유도하기 위해서 강제로 토글시킴 */
          menu.removeAttribute('active')
          await this.updateComplete
          menu.setAttribute('active', '')
        }

        this.activeIndex = index
      } else {
        menu.removeAttribute('active')
      }
    })
  }

  /**
   * Static method to open a popup menu with the provided template and position options.
   * Creates and returns an instance of OxPopupMenu.
   *
   * @param {PopupOpenOptions}
   */
  static open({
    template,
    top,
    left,
    right,
    bottom,
    parent
  }: {
    template: unknown
    top?: number
    left?: number
    right?: number
    bottom?: number
    parent?: Element | null
  }): OxPopupMenu {
    const target = document.createElement('ox-popup-menu') as OxPopupMenu
    render(template, target)

    target.setAttribute('active', '')

    if (parent) {
      var { left, top, right, bottom } = convertToFixedPosition({
        left,
        top,
        right,
        bottom,
        relativeElement: parent as HTMLElement
      })
    }

    document.body.appendChild(target)
    target.removeAfterUse = true
    target.open({ top, left, right, bottom })

    return target
  }
}
