import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { OxPopupMenu } from './ox-popup-menu'

/**
 * Custom element representing a menu item within an OxPopup menu.
 * It can contain a label and an optional submenu.
 */
@customElement('ox-popup-menuitem')
export class OxPopupMenuItem extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
      }

      [icon] {
        width: 20px;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0 var(--spacing-small) 0 0;
        align-items: center;
        justify-content: center;
      }

      [icon] > * {
        flex: 1;
      }

      [label] {
        flex: 1;
        text-transform: capitalize;
      }

      ::slotted(*[slot='icon']) {
        color: var(--ox-popup-menu-color-variant, var(--md-sys-color-on-surface-variant));
        font-size: var(--icon-size-small);
      }

      md-icon {
        display: block;
        width: 24px;
        text-align: right;
        font-size: var(--icon-size-small);
        color: var(--ox-popup-menu-color-variant, var(--md-sys-color-primary));
        opacity: 0.7;
      }
    `
  ]

  /**
   * Property indicating whether the menu item is active or not.
   * When active, it may show a submenu.
   */
  @property({ type: Boolean }) active: boolean = false

  /**
   * The label to display for the menu item.
   */
  @property({ type: String }) label!: string

  @state() _submenu?: OxPopupMenu

  render() {
    return html`
      <div icon>
        <slot name="icon"> </slot>
      </div>
      <div label>${this.label}</div>

      ${this._submenu ? html`<md-icon>chevron_right</md-icon>` : html``}

      <slot @slotchange=${this._onslotchange}> </slot>
    `
  }

  firstUpdated() {
    this.setAttribute('tabindex', '0')
    this.addEventListener('keydown', this._onkeydown)
    this.addEventListener('click', this._onclick)
  }

  protected _onclick: (e: MouseEvent) => void = function (this: OxPopupMenuItem, e: MouseEvent) {
    if (!this._submenu) {
      return
    }

    e.stopPropagation()

    const parent = this.closest('ox-popup-menu') as OxPopupMenu
    if (parent) {
      parent.setActive(this)
    }

    this.dispatchEvent(new CustomEvent('select'))

    requestAnimationFrame(() => {
      this.expand(false)
    })
  }.bind(this)

  protected _onkeydown: (e: KeyboardEvent) => void = function (this: OxPopupMenuItem, e: KeyboardEvent) {
    switch (e.key) {
      case 'Right':
      case 'ArrowRight':
        e.stopPropagation()
        this.expand(false)
        break

      case 'Left':
      case 'ArrowLeft':
        e.stopPropagation()
        this.collapseSelf()
        break

      case 'Enter':
        if (this._submenu) {
          e.stopPropagation()
          this.expand(false)
        }
        break
    }
  }.bind(this)

  protected _onslotchange: (e: Event) => void = function (this: OxPopupMenuItem, e: Event) {
    this._submenu = this.querySelector('ox-popup-menu') as OxPopupMenu
  }.bind(this)

  updated(changes: PropertyValues<this>) {
    if (changes.has('active')) {
      this.updateActive()
    }
  }

  updateActive() {
    if (this.active) {
      this.expand(true)
    } else {
      this.collapse()
    }
  }

  /**
   * Expands the submenu, if it exists.
   * The submenu is displayed below and to the right of the menu item.
   *
   * @param {boolean} silent - If true, the submenu is opened silently without user interaction.
   */
  expand(silent: boolean) {
    if (!this._submenu) {
      return
    }

    const top = 0
    const left = this.clientWidth

    this._submenu.open({ top, left, silent })
  }

  /**
   * Collapses the submenu, if it exists.
   */
  collapse() {
    this._submenu?.close()
  }

  /**
   * Dispatches a custom 'ox-collapse' event indicating that the menu item should collapse itself.
   * This event can be used to trigger further interactions or logic in the application.
   */
  collapseSelf() {
    this.dispatchEvent(new CustomEvent('ox-collapse', { bubbles: true, composed: true, detail: this }))
  }

  /**
   * Dispatches a custom 'ox-close' event indicating that the menu item should close itself.
   * This event can be used to trigger further interactions or logic in the application.
   */
  close() {
    this.dispatchEvent(new CustomEvent('ox-close', { bubbles: true, composed: true, detail: this }))
  }
}
