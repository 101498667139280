import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { createClient as createWebSocketClient } from 'graphql-ws'

import { ApolloLink, from, HttpLink, HttpOptions, Observable, ServerParseError, split } from '@apollo/client/core'
import { ErrorLink, onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'

import { decodeHTML } from '@operato/utils'
import { activeRequestCounterLink, decreaseActiveRequestCounter } from './active-request-counter-link'

export const GRAPHQL_URI = '/graphql'
export const SUBSCRIPTION_URI = GRAPHQL_URI

var apolloLink: ApolloLink

const httpOptions: HttpOptions = {
  uri: GRAPHQL_URI,
  credentials: 'include',
  headers: { 'Apollo-Require-Preflight': 'true' }
}

const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink(httpOptions) as any,
  new HttpLink(httpOptions)
)

const wsLink = new GraphQLWsLink(
  createWebSocketClient({
    url: location.origin.replace(/^http/, 'ws') + SUBSCRIPTION_URI,
    keepAlive: 10_000,
    retryAttempts: 1_000_000,
    shouldRetry: e => true,
    connectionParams: {
      headers: {
        /* 
        특정 도메인의 데이타만 받고자 하는 경우에, referer 정보를 제공해서 서버에서 서브도메인 정보를 취득하도록 한다.
        referer: location.href
        또는, 이미 서브도메인 정보를 알고 있다면,
        'x-things-factory-domain': '[subdomain]'
        을 보낼 수 있다.
        관련 정보를 보내지 않는다면, 사용자가 권한을 가진 모든 도메인의 데이타를 수신하게 된다.
      */
        referer: location.href
      }
    }
  })
)

const ERROR_HANDLER: ErrorLink.ErrorHandler = ({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    document.dispatchEvent(
      new CustomEvent('notify', {
        detail: {
          level: 'error',
          message: decodeHTML(graphQLErrors[0].message),
          ex: graphQLErrors
        }
      })
    )
  }

  if (networkError) {
    decreaseActiveRequestCounter()

    /* networkError가 ServerParseError 이거나 ServerError 인 경우에만 statusCode를 갖는다. */
    switch ((networkError as ServerParseError).statusCode) {
      case undefined /* in case this error is not a server side error */:
        document.dispatchEvent(
          new CustomEvent('notify', {
            detail: {
              level: 'error',
              message: networkError.message,
              ex: networkError
            }
          })
        )
        break

      case 401:
        /* 401 에러가 리턴되면, 인증이 필요하다는 메시지를 dispatch 한다. 이 auth 모듈 등에서 이 메시지를 받아서 signin 프로세스를 진행할 수 있다. */
        document.dispatchEvent(new CustomEvent('auth-required'))
        break

      case 403:
        /* 403 에러가 리턴되면, 도메인 정보가 필요하다는 메시지를 dispatch 한다. 이 auth 모듈 등에서 이 메시지를 받아서 domain-register 프로세스 등을 진행할 수 있다. */
        document.dispatchEvent(new CustomEvent('domain-required'))
        break

      default:
        var { name, response, statusCode, bodyText, message } = networkError as ServerParseError
        if (name == 'ServerParseError') {
          message = `[ ${statusCode || ''} : ${response.statusText} ] ${bodyText}`
        } else {
          /* in case this error is instanceof ServerError */
          message = `[ ${statusCode || ''} : ${response.statusText} ] ${message}`
        }

        document.dispatchEvent(
          new CustomEvent('notify', {
            detail: {
              level: 'error',
              message: decodeHTML(message),
              ex: networkError
            }
          })
        )
    }
  }
}

export const setClientLink = (link: ApolloLink) => {
  apolloLink = link
}

export const getClientLink = () => {
  if (!apolloLink) {
    apolloLink = split(
      ({ query }) => {
        const def = getMainDefinition(query)
        return def.kind === 'OperationDefinition' && def.operation === 'subscription'
      },
      wsLink,
      from([activeRequestCounterLink, onError(ERROR_HANDLER), httpLink])
    )
  }

  return apolloLink
}

export const createMockLink = (mockedResponses: any = {}) =>
  new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      const operationName = operation.operationName

      if (mockedResponses[operationName]) {
        observer.next({
          data: mockedResponses[operationName]
        })
      } else {
        observer.error(new Error(`No mock response for operation ${operationName}`))
      }

      observer.complete()
    })
  })
