import { css } from 'lit'

export const ScrollbarStyles = css`
  ::-webkit-scrollbar {
    width: var(--scrollbar-width, 5px);
    height: var(--scrollbar-height, 5px);
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color, var(--md-sys-color-outline));
    border-radius: var(--scrollbar-thumb-border-radius, 0);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color, var(--md-sys-color-outline-variant));
  }

  :host::-webkit-scrollbar {
    width: var(--scrollbar-width, 5px);
    height: var(--scrollbar-height, 5px);
  }
  :host::-webkit-scrollbar-track {
    background-color: transparent;
  }
  :host::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color, var(--md-sys-color-outline));
  }
  :host::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover-color, var(--md-sys-color-outline-variant));
  }
`
