import '@operato/popup/ox-floating-overlay.js'
import '../components/ox-resize-splitter.js'

import { css, html, LitElement } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { customElement, property, state } from 'lit/decorators.js'
import { connect } from 'pwa-helpers/connect-mixin.js'

import { Viewpart, VIEWPART_LEVEL } from '../actions/layout.js'
import store from '../initializer.js'

@customElement('ox-footer-bar')
class FooterBar extends connect(store)(LitElement) {
  static styles = [
    css`
      :host {
        display: flex;
        flex-flow: column-reverse nowrap;
        align-items: stretch;
        position: relative;
      }

      *[footerbar] {
        display: block;
        max-width: 100vw;
      }

      @media screen and (max-width: 460px) {
        :host {
          padding-bottom: 0;
        }
      }
    `
  ]

  @property({ type: Boolean, attribute: 'fullbleed' }) fullbleed: boolean = false

  @state() viewparts: { [name: string]: Viewpart } = {}

  private _startHeight: number = 0

  render() {
    var viewparts = this.viewparts
    var footerbars = Object.keys(viewparts)
      .map(name => {
        return {
          name,
          ...viewparts[name]
        }
      })
      .filter(viewpart => viewpart.position == 'footerbar' && (!this.fullbleed || viewpart.hovering))

    footerbars = [
      ...footerbars.filter(viewpart => viewpart.level == VIEWPART_LEVEL.TOPMOST),
      ...footerbars.filter(viewpart => viewpart.level !== VIEWPART_LEVEL.TOPMOST)
    ]

    return html`
      ${footerbars.map(footerbar =>
        !footerbar.show
          ? html``
          : footerbar.hovering
            ? html`
                <ox-floating-overlay
                  .backdrop=${footerbar.backdrop}
                  direction="up"
                  .hovering=${footerbar.hovering}
                  .name=${footerbar.name}
                  .title=${footerbar.title || ''}
                  .help=${footerbar.help}
                  .size=${footerbar.size}
                  .closable=${footerbar.closable}
                  .templateProperties=${footerbar.templateProperties}
                  .historical=${true}
                  .search=${footerbar.search}
                  .filter=${footerbar.filter}
                  z-index=${ifDefined(footerbar.zIndex)}
                  >${footerbar.template}</ox-floating-overlay
                >
              `
            : html`
                <div footerbar>${footerbar.template}</div>
                ${footerbar.resizable
                  ? html`
                      <ox-resize-splitter
                        @splitter-dragstart=${(e: CustomEvent) => this.resizeStart(e)}
                        @splitter-drag=${(e: CustomEvent) => this.resizeDrag(e)}
                      ></ox-resize-splitter>
                    `
                  : html``}
              `
      )}
    `
  }

  resizeStart(e: CustomEvent) {
    this._startHeight = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement).offsetHeight
  }

  resizeDrag(e: CustomEvent) {
    var delta = e.detail

    var x = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement).children
    Array.from(x).forEach(ele => {
      ;(ele as HTMLElement).style.height = `${this._startHeight - delta.y}px`
    })
  }

  stateChanged(state: any) {
    this.viewparts = state.layout.viewparts || {}
  }
}
