/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'

// import debounce from 'lodash-es/debounce'
import { css, html } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-search')
export class OxInputSearch extends OxFormField {
  static styles = [
    css`
      :host {
        display: flex;
        position: relative;
        align-items: center;

        color: var(--ox-input-search-color, var(--md-sys-color-on-surface-variant));
        background-color: var(--ox-input-search-background-color, var(--md-sys-color-surface-variant));

        --md-icon-size: 20px;
      }

      [type='text'] {
        flex: 1;
        color: inherit;
        background-color: transparent;
        border: 0;
        padding: var(--input-search-padding, var(--spacing-tiny));
        padding-left: 25px;
        overflow: hidden;
      }

      [type='text']:focus {
        outline: none;
      }

      md-icon {
        position: absolute;
      }

      input {
        width: 100%;
      }
    `
  ]

  @property({ type: String }) placeholder?: string
  @property({ attribute: 'select-after-change', type: Boolean }) selectAfterChange?: boolean

  @query('input') input!: HTMLInputElement

  // private dispatchChangeEventDebouncer = debounce((value: string) => this.dispatchChangeEvent(value), 100)

  render() {
    return html`
      <md-icon @click=${(e: Event) => !this.disabled && this._onClickSearch(e)}>search</md-icon>
      <input
        type="text"
        .value=${this.value || ''}
        .placeholder=${this.placeholder || ''}
        @change=${(e: Event) => {
          e.stopPropagation()
          this.dispatchChangeEvent((e.target as HTMLInputElement).value)
          // this.dispatchChangeEventDebouncer((e.target as HTMLInputElement).value)
        }}
        @keydown=${(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            if ((e.target as HTMLInputElement).value == this.value) {
              /* Even if the value has not changed, the enter key triggers a change event. */
              e.preventDefault() /* Prevent change event from occurring twice. */

              this.input.dispatchEvent(
                new CustomEvent('change', {
                  detail: (e.target as HTMLInputElement).value
                })
              )
            }
          }
        }}
        ?disabled=${this.disabled}
      />
    `
  }

  firstUpdated() {
    if (this.autofocus) {
      this.input.focus()
    }
  }

  dispatchChangeEvent(value: string) {
    this.value = value

    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: value
      })
    )

    if (this.selectAfterChange) {
      requestAnimationFrame(() => {
        this.input.select()
      })
    }
  }

  _onClickSearch(e: Event) {
    if (this._form) {
      e.stopPropagation()
      this._form.requestSubmit()
    } else {
      this.input.dispatchEvent(
        new CustomEvent('change', {
          detail: this.input.value
        })
      )
    }
  }
}
