import '@material/web/icon/icon.js'
import '@operato/input/ox-input-search.js'

import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'

import { ScrollbarStyles } from '@operato/styles'
import { isHandheldDevice } from '@operato/utils'

function getPoint(e: Event): { x: number; y: number } | undefined {
  if ((e as MouseEvent).button == 0) {
    return {
      x: (e as MouseEvent).clientX,
      y: (e as MouseEvent).clientY
    }
  } else if ((e as TouchEvent).touches?.length == 1) {
    const touch = (e as TouchEvent).touches[0]
    return {
      x: touch.clientX,
      y: touch.clientY
    }
  } else {
    return
  }
}

/**
 * Custom element for creating floating overlays.
 * These overlays can have various properties like direction, size, title, and more.
 * They are often used for modal dialogs, pop-ups, and similar UI elements.
 */
@customElement('ox-floating-overlay')
export class OxFloatingOverlay extends LitElement {
  static styles = [
    ScrollbarStyles,
    css`
      /* for layout style */
      :host {
        position: relative;
        z-index: var(--z-index, 10);
        box-shadow: 2px 3px 10px 5px rgba(0, 0, 0, 0.15);
      }

      :host([hovering='edge']) {
        /* edge hovering 인 경우에는 상위 relative position 크기와 위치를 반영한다. */
        position: initial;
      }

      #backdrop {
        position: fixed;
        left: 0;
        top: 0;

        width: 100vw;
        height: 100vh;
        height: 100dvh;

        background-color: var(--md-sys-color-shadow, #000);
        opacity: 0.4;
      }

      [overlayed] {
        position: absolute;

        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      [overlayed][hovering='center'] {
        position: fixed;

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        opacity: 0;
        border: 2px solid var(--md-sys-color-primary);
      }

      [overlayed][hovering='center'][opened] {
        opacity: 1;
        transition: opacity 0.3s ease-in;
        background-color: var(--md-sys-color-surface-container-lowest);
      }

      [hovering='center'] {
        width: var(--overlay-center-normal-width, 60%);
        height: var(--overlay-center-normal-height, 60%);
      }

      [hovering='center'][size='small'] {
        width: var(--overlay-center-small-width, 40%);
        height: var(--overlay-center-small-height, 40%);
      }

      [hovering='center'][size='large'] {
        width: var(--overlay-center-large-width, 100%);
        height: var(--overlay-center-large-height, 100%);
      }

      [header] {
        --help-icon-color: var(--md-sys-color-on-primary-container);
        --help-icon-hover-color: var(--md-sys-color-on-primary-container);

        pointer-events: initial;
      }

      [content] {
        flex: 1;
        overflow: hidden;
      }

      ::slotted(*) {
        box-sizing: border-box;
        pointer-events: initial;
      }

      [hovering='center'] [content] ::slotted(*) {
        width: 100%;
        height: 100%;
      }
      [direction='up'],
      [direction='down'] {
        width: 100%;

        max-height: 0;
        transition: max-height 0.7s ease-in;
      }
      [direction='up'] {
        bottom: 0;
      }
      [direction='down'] {
        top: 0;
      }

      [direction='up'][opened],
      [direction='down'][opened] {
        max-height: 100vh;
        max-height: 100dvh;
      }

      [settled][direction='down'] [content],
      [settled][direction='up'] [content] {
        overflow-y: auto;
      }

      [direction='left'],
      [direction='right'] {
        height: 100%;

        max-width: 0;
        transition: max-width 0.5s ease-in;
      }
      [direction='left'] {
        right: 0;
      }
      [direction='right'] {
        left: 0;
      }

      [direction='left'][opened],
      [direction='right'][opened] {
        max-width: 100vw;
      }

      [settled][direction='left'] [content],
      [settled][direction='right'] [content] {
        overflow-x: auto;
      }

      @media screen and (max-width: 460px) {
        [direction='up'],
        [direction='down'] {
          max-height: 100vh;
          max-height: 100dvh;
        }

        [direction='left'],
        [direction='right'] {
          max-width: 100vw;
        }
      }
    `,
    css`
      /* for header style */
      [header] {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--md-sys-color-primary);
        font-weight: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500));
        color: var(--md-sys-color-on-primary);
      }

      slot[name='header'] {
        flex: 1;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
      }

      [name='header']::slotted(*) {
        margin: 0 auto;
      }

      [name='header'] > h1 {
        font-size: var(--md-sys-typescale-title-medium-size, 1rem);
        margin-block: 0.4rem;
      }

      [historyback] {
        margin-left: var(--spacing-medium);
        margin-right: auto;
      }

      [close] {
        margin-left: auto;
        margin-right: var(--spacing-medium);
      }

      [historyback],
      [close] {
        display: none;
      }

      [closable][close] {
        display: block;
        cursor: pointer;
      }

      [search] ox-help-icon {
        color: var(--md-sys-color-on-secondary-container);
      }

      [search] {
        --help-icon-color: var(--md-sys-color-primary);
        --help-icon-hover-color: var(--md-sys-color-primary);
        --help-icon-opacity: 0.2;
        --help-icon-size: 20px;

        --md-icon-size: 20px;

        --input-search-padding: var(--spacing-medium);
        --input-search-focus-border-bottom: none;
        --input-search-font: normal 16px var(--theme-font);

        display: flex;
        justify-content: space-between;
        align-items: center;

        align-self: center;
        color: var(--md-sys-color-primary);
        background-color: var(--md-sys-color-on-primary);
        border-radius: 999em;
        padding: 0 var(--spacing-medium);
      }

      [search] > * {
        align-self: center;
      }

      ox-input-search {
        color: var(--md-sys-color-primary);
        background-color: var(--md-sys-color-on-primary);
        border-radius: var(--md-sys-shape-corner-full);
      }

      @media screen and (max-width: 460px) {
        [closable][historyback] {
          display: block;
        }

        [closable][close] {
          display: none;
        }
      }
    `
  ]

  /**
   * A boolean property that determines whether a backdrop should be displayed behind the overlay.
   * Backdrop provides a semi-transparent background that covers the entire screen when the overlay is open.
   */
  @property({ type: Boolean }) backdrop?: boolean = false

  /**
   * A string property that specifies the direction in which the overlay should appear.
   * Possible values are: 'up', 'down', 'left', or 'right'.
   */
  @property({ type: String }) direction?: 'up' | 'down' | 'left' | 'right'

  /**
   * A string property that reflects the hovering state of the overlay.
   * Possible values are: 'center', 'edge', or 'next'.
   */
  @property({ type: String, reflect: true }) hovering?: 'center' | 'edge' | 'next'

  /**
   * A string property that specifies the size of the overlay.
   * Possible values are: 'small', 'medium', or 'large'.
   */
  @property({ type: String }) size?: 'small' | 'medium' | 'large'

  /**
   * A string property that represents the name of the overlay.
   * This can be used for identification or other purposes.
   */
  @property({ type: String }) name?: string

  /**
   * A string property that sets the title of the overlay.
   * The title is typically displayed in the header of the overlay.
   */
  @property({ type: String }) title: string = ''

  /**
   * A boolean property that determines whether the overlay can be closed by the user.
   * If set to true, a close button will be displayed in the header.
   */
  @property({ type: Boolean }) closable?: boolean = false

  /**
   * An object property that can hold custom properties for the template of the overlay.
   * These properties can be used to customize the template's behavior.
   */
  @property({ type: Object }) templateProperties: any

  /**
   * An object property that can hold information related to help or assistance for the overlay.
   * This information may be used to provide additional guidance to users.
   */
  @property({ type: Object }) help: any

  /**
   * A boolean property that determines whether the overlay is considered historical.
   * Historical overlays may have specific behavior or interactions, such as navigating back in history.
   */
  @property({ type: Boolean }) historical?: boolean = false

  /**
   * A boolean property that determines whether the overlay can be moved by dragging.
   * If set to true, the overlay can be repositioned by dragging its header.
   */
  @property({ type: Boolean }) movable?: boolean = false

  /**
   * An object property that can hold information related to a search feature within the overlay.
   * It can include properties like 'value', 'handler', and 'placeholder'.
   */
  @property({ type: Object }) search?: {
    value?: string
    handler?: (instance: any, value: string) => void
    placeholder?: string
  }

  /**
   * An object property that can hold information related to a filter feature within the overlay.
   * It can include a 'handler' function for filtering content.
   */
  @property({ type: Object }) filter?: { handler?: (instance: any) => void }

  /**
   * A numeric property that specifies the z-index of the overlay.
   * The z-index determines the stacking order of the overlay in relation to other elements on the page.
   */
  @property({ type: Number, attribute: 'z-index' }) zIndex?: number

  private dragStart?: { x: number; y: number }
  private dragEndHandler = this.onDragEnd.bind(this) as EventListener
  private dragMoveHandler = this.onDragMove.bind(this) as EventListener

  @query('[overlayed]') overlayed!: HTMLDivElement
  @query('[content]') content!: HTMLDivElement

  render() {
    const direction = this.hovering == 'center' ? undefined : this.direction

    const { value = '', handler: searchHandler, placeholder = '', autofocus = true } = this.search || ({} as any)
    const { handler: filterHandler } = this.filter || ({} as any)

    const searchable = typeof searchHandler == 'function'
    const filterable = typeof filterHandler == 'function'

    return html`
      ${Boolean(this.backdrop)
        ? html`
            <div
              id="backdrop"
              ?hidden=${!this.backdrop}
              @click=${(e: Event) => this.onClose(e, true /* escape */)}
            ></div>
          `
        : html``}

      <div
        overlayed
        hovering=${this.hovering || 'center'}
        direction=${ifDefined(direction)}
        size=${this.size || 'medium'}
        @close-overlay=${(e: Event) => {
          e.stopPropagation()
          this.onClose(e)
        }}
        @transitionstart=${(e: Event) => {
          /* to hide scrollbar during transition */
          ;(e.target as HTMLElement).removeAttribute('settled')
        }}
        @transitionend=${(e: Event) => {
          ;(e.target as HTMLElement).setAttribute('settled', '')
        }}
        @click=${(e: MouseEvent) => {
          if (this.backdrop && e.target === this.content) {
            this.onClose(e, true /* escape */)
          }
        }}
      >
        <div
          header
          @mousedown=${this.onDragStart.bind(this)}
          @touchstart=${this.onDragStart.bind(this)}
          draggable="false"
        >
          <md-icon @click=${(e: Event) => this.onClose(e)} ?closable=${this.closable} historyback>arrow_back</md-icon>
          ${this.movable ? html`<md-icon>drag_indicator</md-icon>` : html``}
          <slot name="header">
            ${this.title || this.closable
              ? html`
                  <h1>
                    ${this.title || ''}&nbsp;${this.help
                      ? html`<ox-help-icon .topic=${this.help}></ox-help-icon>`
                      : html``}
                  </h1>
                `
              : html``}
            ${searchable || filterable
              ? html`
                  <div search>
                    ${searchable
                      ? html` <ox-input-search
                          .placeholder=${placeholder}
                          .value=${value || ''}
                          ?autofocus=${autofocus}
                          @change=${(e: Event) => {
                            searchHandler(this.firstElementChild, (e.target as any).value)
                          }}
                        ></ox-input-search>`
                      : html``}
                    ${this.help && searchable ? html`<ox-help-icon .topic=${this.help}></ox-help-icon>` : html``}
                    ${filterable
                      ? html`<md-icon @click=${(e: MouseEvent) => filterHandler(this.firstElementChild)}>tune</md-icon>`
                      : html``}
                  </div>
                `
              : html``}
            ${this.help && !searchable && !this.title /* help only */
              ? html`<ox-help-icon .topic=${this.help}></ox-help-icon>`
              : html``}
          </slot>
          <md-icon @click=${(e: Event) => this.onClose(e)} ?closable=${this.closable} close>close</md-icon>
        </div>

        <div content>
          <slot> </slot>
        </div>
      </div>
    `
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('templateProperties') && this.templateProperties) {
      var template = this.firstElementChild
      if (template) {
        for (let prop in this.templateProperties) {
          //@ts-ignore
          template[prop] = this.templateProperties[prop]
        }
      }
    }
  }

  firstUpdated() {
    if (this.zIndex) {
      this.style.setProperty('--z-index', String(this.zIndex))
    }

    requestAnimationFrame(() => {
      /* transition(animation) 효과를 위해 'opened' 속성을 변화시킨다. */
      this.overlayed?.setAttribute('opened', 'true')
    })
  }

  connectedCallback(): void {
    super.connectedCallback()

    this.movable = this.movable && !isHandheldDevice()

    if (this.movable) {
      document.addEventListener('mouseup', this.dragEndHandler)
      document.addEventListener('touchend', this.dragEndHandler)
      document.addEventListener('touchcancel', this.dragEndHandler)
      document.addEventListener('mousemove', this.dragMoveHandler)
      document.addEventListener('touchmove', this.dragMoveHandler)
    }
  }

  disconnectedCallback() {
    document.dispatchEvent(
      new CustomEvent('overlay-closed', {
        detail: this.name
      })
    )

    if (this.movable) {
      document.removeEventListener('mouseup', this.dragEndHandler!)
      document.removeEventListener('touchend', this.dragEndHandler!)
      document.removeEventListener('touchcancel', this.dragEndHandler!)
      document.removeEventListener('mousemove', this.dragMoveHandler!)
      document.removeEventListener('touchmove', this.dragMoveHandler!)
    }

    super.disconnectedCallback()
  }

  onDragStart(e: Event) {
    if (!this.movable) {
      return
    }

    const point = getPoint(e)

    if (point) {
      this.dragStart = point
      e.stopPropagation()
      return false
    }
  }

  onDragMove(e: Event) {
    if (!this.movable || !this.dragStart) {
      return false
    }

    const point = getPoint(e)

    if (!point) {
      return false
    }

    e.stopPropagation()
    e.preventDefault()

    const dragStart = point
    var { x, y } = point

    x -= this.dragStart.x
    y -= this.dragStart.y

    this.dragStart = dragStart

    const overlayed = this.overlayed

    var boundingRect = overlayed.getBoundingClientRect()

    overlayed.style.left =
      Math.min(document.body.offsetWidth - 40, Math.max(40 - overlayed.offsetWidth, boundingRect.left + x)) + 'px'
    overlayed.style.top = Math.min(document.body.offsetHeight - 40, Math.max(0, boundingRect.top + y)) + 'px'

    overlayed.style.transform = 'initial'

    return false
  }

  onDragEnd(e: Event) {
    if (this.movable && this.dragStart) {
      e.stopPropagation()
      e.preventDefault()

      delete this.dragStart
    }
  }

  /**
   * A method that closes the overlay by removing it from its parent node in the DOM.
   * When called, this method removes the overlay element, effectively hiding it from the user interface.
   */
  close() {
    this.parentNode?.removeChild(this)
  }

  onClose(e: Event, escape?: boolean) {
    e.stopPropagation()
    /* 현재 overlay state를 확인해서, 자신이 포함하고 있는 템플릿인 경우에 history.back() 한다. */

    if (this.historical) {
      var state = history.state
      var overlay = (state || {}).overlay

      if (!overlay || overlay.name !== this.name) {
        return
      }

      /* Backdrop click 경우는 escape 시도라고 정의한다. overlay 속성이 escapable이 아닌 경우에는 동작하지 않는다. */
      if (escape && !overlay.escapable) {
        return true
      }

      history.back()
    } else {
      this.close()
    }
  }
}
