import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client/core'

import { getClientLink } from './graphql-env'

export const GRAPHQL_URI = '/graphql'
export const SUBSCRIPTION_URI = GRAPHQL_URI

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache', //'network-only'
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
}

const cache = new InMemoryCache({
  addTypename: false
  // dataIdFromObject: object => object.key
})

export const client = new ApolloClient({
  defaultOptions,
  cache,
  link: getClientLink()
})

export const subscribe = async (request: any, subscribe: any) => {
  return client.subscribe(request).subscribe(subscribe)
}
