// migrated from https://github.com/i18next/i18next-http-backend
// due to import module problem

import { ModuleType } from 'i18next'
import { makePromise } from './utils.js'
import { request } from './request.js'

const getDefaults = () => {
  return {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    addPath: '/locales/add/{{lng}}/{{ns}}',
    allowMultiLoading: false,
    parse: (data: any) => JSON.parse(data),
    stringify: JSON.stringify,
    parsePayload: (namespace: any, key: string, fallbackValue: any) => ({ [key]: fallbackValue || '' }),
    request,
    reloadInterval: typeof window !== 'undefined' ? false : 60 * 60 * 1000,
    customHeaders: {},
    queryStringParams: {},
    crossDomain: false, // used for XmlHttpRequest
    withCredentials: false, // used for XmlHttpRequest
    overrideMimeType: false, // used for XmlHttpRequest
    requestOptions: {
      // used for fetch
      mode: 'cors',
      credentials: 'same-origin',
      cache: 'default'
    }
  }
}

export class Backend {
  static type: ModuleType = 'backend'

  services: any
  options: any
  allOptions: any
  type: string

  constructor(services: any, options: any = {}, allOptions: any = {}) {
    this.services = services
    this.options = options
    this.allOptions = allOptions
    this.type = 'backend'
    this.init(services, options, allOptions)
  }

  init(services: any, options: any = {}, allOptions: any = {}) {
    this.services = services
    this.options = {
      ...getDefaults(),
      ...this.options,
      ...options
    }
    this.allOptions = allOptions
    if (this.services && this.options.reloadInterval) {
      setInterval(() => this.reload(), this.options.reloadInterval)
    }
  }

  readMulti(languages: any, namespaces: any, callback: any) {
    this._readAny(languages, languages, namespaces, namespaces, callback)
  }

  read(language: any, namespace: any, callback: any) {
    this._readAny([language], language, [namespace], namespace, callback)
  }

  _readAny(languages: any, loadUrlLanguages: any, namespaces: any, loadUrlNamespaces: any, callback: any) {
    let loadPath = this.options.loadPath
    if (typeof this.options.loadPath === 'function') {
      loadPath = this.options.loadPath(languages, namespaces)
    }

    loadPath = makePromise(loadPath)

    loadPath.then((resolvedLoadPath: any) => {
      const url = this.services.interpolator.interpolate(resolvedLoadPath, {
        lng: languages.join('+'),
        ns: namespaces.join('+')
      })
      this.loadUrl(url, callback, loadUrlLanguages, loadUrlNamespaces)
    })
  }

  loadUrl(url: string, callback: any, languages: any, namespaces: any) {
    this.options.request(this.options, url, undefined, (err: any, res: any) => {
      if (res && ((res.status >= 500 && res.status < 600) || !res.status))
        return callback('failed loading ' + url + '; status code: ' + res.status, true /* retry */)
      if (res && res.status >= 400 && res.status < 500)
        return callback('failed loading ' + url + '; status code: ' + res.status, false /* no retry */)
      if (!res && err && err.message && err.message.indexOf('Failed to fetch') > -1)
        return callback('failed loading ' + url + ': ' + err.message, true /* retry */)
      if (err) return callback(err, false)

      let ret, parseErr
      try {
        if (typeof res.data === 'string') {
          ret = this.options.parse(res.data, languages, namespaces)
        } else {
          // fallback, which omits calling the parse function
          ret = res.data
        }
      } catch (e) {
        parseErr = 'failed parsing ' + url + ' to json'
      }
      if (parseErr) return callback(parseErr, false)
      callback(null, ret)
    })
  }

  create(languages: any, namespace: any, key: any, fallbackValue: any, callback: any) {
    // If there is a falsey addPath, then abort -- this has been disabled.
    if (!this.options.addPath) return
    if (typeof languages === 'string') languages = [languages]
    const payload = this.options.parsePayload(namespace, key, fallbackValue)
    let finished = 0
    const dataArray: any = []
    const resArray: any = []
    languages.forEach((lng: any) => {
      let addPath = this.options.addPath
      if (typeof this.options.addPath === 'function') {
        addPath = this.options.addPath(lng, namespace)
      }
      const url = this.services.interpolator.interpolate(addPath, { lng: lng, ns: namespace })

      this.options.request(this.options, url, payload, (data: any, res: any) => {
        // TODO: if res.status === 4xx do log
        finished += 1
        dataArray.push(data)
        resArray.push(res)
        if (finished === languages.length) {
          if (callback) callback(dataArray, resArray)
        }
      })
    })
  }

  reload() {
    const { backendConnector, languageUtils, logger } = this.services
    const currentLanguage = backendConnector.language
    if (currentLanguage && currentLanguage.toLowerCase() === 'cimode') return // avoid loading resources for cimode

    const toLoad: any = []
    const append = (lng: any) => {
      const lngs = languageUtils.toResolveHierarchy(lng)
      lngs.forEach((l: any) => {
        if (toLoad.indexOf(l) < 0) toLoad.push(l)
      })
    }

    append(currentLanguage)

    if (this.allOptions.preload) this.allOptions.preload.forEach((l: any) => append(l))

    toLoad.forEach((lng: any) => {
      this.allOptions.ns.forEach((ns: any) => {
        backendConnector.read(lng, ns, 'read', null, null, (err: any, data: any) => {
          if (err) logger.warn(`loading namespace ${ns} for language ${lng} failed`, err)
          if (!err && data) logger.log(`loaded namespace ${ns} for language ${lng}`, data)

          backendConnector.loaded(`${lng}|${ns}`, err, data)
        })
      })
    })
  }
}
