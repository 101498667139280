import '@operato/popup/ox-floating-overlay.js'
import '../components/ox-resize-splitter.js'

import { css, html, LitElement } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { customElement, property, state } from 'lit/decorators.js'
import { connect } from 'pwa-helpers/connect-mixin.js'

import { ScrollbarStyles } from '@operato/styles'

import { Viewpart, VIEWPART_LEVEL } from '../actions/layout.js'
import store from '../initializer.js'

@customElement('ox-aside-bar')
class AsideBar extends connect(store)(LitElement) {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-flow: row-reverse nowrap;
        align-items: stretch;

        position: relative;
      }

      *[asidebar] {
        display: block;
        overflow-y: auto;
      }
    `
  ]

  @property({ type: Boolean, attribute: 'fullbleed' }) fullbleed: boolean = false

  @state() viewparts: { [name: string]: Viewpart } = {}

  private _startWidth: number = 0

  render() {
    var viewparts = this.viewparts
    var asidebars = Object.keys(viewparts)
      .map(name => {
        return {
          name,
          ...viewparts[name]
        }
      })
      .filter(viewpart => viewpart.position == 'asidebar' && (!this.fullbleed || viewpart.hovering))

    asidebars = [
      ...asidebars.filter(viewpart => viewpart.level == VIEWPART_LEVEL.TOPMOST),
      ...asidebars.filter(viewpart => viewpart.level !== VIEWPART_LEVEL.TOPMOST)
    ]

    return html`
      ${asidebars.map(asidebar =>
        !asidebar.show
          ? html``
          : asidebar.hovering
            ? html`
                <ox-floating-overlay
                  .backdrop=${asidebar.backdrop}
                  direction="left"
                  .hovering=${asidebar.hovering}
                  .name=${asidebar.name}
                  .size=${asidebar.size}
                  .title=${asidebar.title}
                  .help=${asidebar.help}
                  .closable=${asidebar.closable}
                  .templateProperties=${asidebar.templateProperties}
                  .historical=${true}
                  .search=${asidebar.search}
                  .filter=${asidebar.filter}
                  z-index=${ifDefined(asidebar.zIndex)}
                  >${asidebar.template}</ox-floating-overlay
                >
              `
            : html`
                <div asidebar>${asidebar.template}</div>
                ${asidebar.resizable
                  ? html`
                      <ox-resize-splitter
                        @splitter-dragstart=${(e: CustomEvent) => this.resizeStart(e)}
                        @splitter-drag=${(e: CustomEvent) => this.resizeDrag(e)}
                        vertical
                      ></ox-resize-splitter>
                    `
                  : html``}
              `
      )}
    `
  }

  resizeStart(e: CustomEvent) {
    this._startWidth = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement)?.offsetWidth
  }

  resizeDrag(e: CustomEvent) {
    var delta = e.detail

    var x = ((e.target as HTMLElement)?.previousElementSibling as HTMLElement)?.children
    Array.from(x).forEach(ele => {
      ;(ele as HTMLElement).style.width = `${this._startWidth - delta.x}px`
    })
  }

  stateChanged(state: any) {
    this.viewparts = state.layout.viewparts || {}
  }
}
