import { css } from 'lit'

export const SpinnerStyles = css`
  #spinner {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 70px;
    height: 70px;
    margin: 0 auto;
    background: var(--oops-spinner-image) 0 0 no-repeat;
    background-size: 700%;
    animation: spinner steps(6) 2s infinite both;
    z-index: 10;
  }

  #spinner[show] {
    display: block;
  }

  @keyframes spinner {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }
`
