/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { Backend } from './http-backend'
import LngDetector from 'i18next-browser-languagedetector'
import _i18next from 'i18next'

const subdomain = location.pathname.match(/\/domain\/([^\/]+)/)?.[1]

_i18next
  .use(LngDetector)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      prefix: '{',
      suffix: '}'
    },
    load: 'languageOnly',
    backend: {
      loadPath: subdomain ? `/domain/${subdomain}/{ns}/{lng}.json` : `/{ns}/{lng}.json`
    },
    detection: {
      // order and from where user language should be detected
      order: ['cookie'],

      // keys or params to lookup language from
      lookupCookie: 'i18next',

      // cache user language on
      caches: ['cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' }
    }
  })

export const i18next = _i18next
